import appStore from 'scss/images/app-store.png';
import download from 'scss/images/download.png';
import inventoryFeeds from 'scss/images/feature-1-1.svg';
import shareOptions from 'scss/images/feature-1-2.svg';
import dashboardReports from 'scss/images/feature-1-3.svg';
import multiVehicleSelection from 'scss/images/feature-1-4.svg';
import googlePlay from 'scss/images/google-play.png';
import inventory from 'scss/images/inventory.png';
import logo from 'scss/images/logo.svg';
import mission from 'scss/images/mission.png';
import rocket from 'scss/images/rocket.svg';
import values1 from 'scss/images/values-1.svg';
import values2 from 'scss/images/values-2.svg';
import values3 from 'scss/images/values-3.svg';
import values4 from 'scss/images/values-4.svg';
import values5 from 'scss/images/values-5.svg';
import values6 from 'scss/images/values-6.svg';
import visionMobDark from 'scss/images/vision-mob-dark.png';
import visionMob from 'scss/images/vision-mob.png';
import Bryanne from 'scss/images/bryanne.jpg';
import linkedIn from 'scss/images/linkedin.png';
import Pulkit from 'scss/images/pulkit.jpg';
import Rachel from 'scss/images/rachel.jpg';
import Sabrina from 'scss/images/sabrina.jpg';
import Umang from 'scss/images/umang.jpg';
import Kerri from 'scss/images/kerri.jpg';
import User from 'scss/images/user.png';
import Rohit from 'scss/images/rohit.png';
import Shash from 'scss/images/shash.jpg';
import Taniya from 'scss/images/taniya.jpg';
import Marty from 'scss/images/marty.jpg';
import Stephen from 'scss/images/stephen.png';

//OurStory Page Images
import story1 from 'scss/images/story-1.png';
import story2 from 'scss/images/story-2.png';
import story3 from 'scss/images/story-3.png';
import story4 from 'scss/images/story-4.png';
import story5 from 'scss/images/story-5.png';
import culture1 from 'scss/images/culture-1.jpg';
import culture2 from 'scss/images/culture-2.jpeg';
import culture3 from 'scss/images/culture-3.jpeg';
import culture4 from 'scss/images/culture-4.jpeg';
import culture5 from 'scss/images/culture-5.jpg';
import culture6 from 'scss/images/culture-6.jpg';
import culture7 from 'scss/images/culture-7.jpg';
import culture8 from 'scss/images/culture-8.jpg';

//Marketing AI page images
import dashAi from 'scss/images/d-ai.png';
import ai1 from 'scss/images/ai-1.png';
import ai2 from 'scss/images/ai-2.png';
import ai3 from 'scss/images/ai-3.png';

// MyDabadu images

import main from 'scss/images/mydabadu/welcome-mydabadu-img.png';
import mainImgRightShape from 'scss/images/mydabadu/main-img-right-shape.png';
import iOSAppStoreImg from 'scss/images/mydabadu/ios-app-store.png';
import androidAppStoreImg from 'scss/images/mydabadu/android-app-store.png';
import keyFeaturesLeftShape from 'scss/images/mydabadu/key-features-left-shape.png';
import keyFeaturesMainImg from 'scss/images/mydabadu/key-features-main-img.png';
import myDabaduLeftShape from 'scss/images/mydabadu/my-dabadu-left-shape.png';
import myDabaduImg1 from 'scss/images/mydabadu/my-dabadu-img-1.png';
import myDabaduImg2 from 'scss/images/mydabadu/my-dabadu-img-2.png';
import aboutUsImg1 from 'scss/images/mydabadu/about-us-img-1.png';
import aboutUsImg2 from 'scss/images/mydabadu/about-us-img-2.png';
import aboutUsImg3 from 'scss/images/mydabadu/about-us-img-3.png';
import encrypted from 'scss/images//mydabadu/faq-icon/encrypted.png';
import car from 'scss/images//mydabadu/faq-icon/car.png';
import mail from 'scss/images//mydabadu/faq-icon/mail.png';
import garageHome from 'scss/images//mydabadu/faq-icon/garage-home.png';
import call from 'scss/images//mydabadu/faq-icon/call.png';
import dollar from 'scss/images//mydabadu/faq-icon/dollar.png';
import carCrash from 'scss/images//mydabadu/faq-icon/car-crash.png';
import altRoute from 'scss/images//mydabadu/faq-icon/alt-route.png';
import truck from 'scss/images//mydabadu/faq-icon/truck.png';
import mobile from 'scss/images//mydabadu/faq-icon/mobile.png';
import ambulance from 'scss/images//mydabadu/faq-icon/ambulance.png';
import downloadAppMainImg from 'scss/images//mydabadu/download-app-main-img.png';

export const commonImages = {
  appStore,
  googlePlay,
  logo,
  inventoryFeeds,
  shareOptions,
  dashboardReports,
  multiVehicleSelection,
  inventory,
  rocket,
  mission,
  visionMob,
  visionMobDark,
  values1,
  values2,
  values3,
  values4,
  values5,
  values6,
  download,
  Bryanne,
  Pulkit,
  Rachel,
  Sabrina,
  Umang,
  Kerri,
  User,
  linkedIn,
  story1,
  story2,
  story3,
  story4,
  story5,
  culture1,
  culture2,
  culture3,
  culture4,
  culture5,
  culture6,
  culture7,
  culture8,
  dashAi,
  ai1,
  ai2,
  ai3,
  Rohit,
  Shash,
  Taniya,
  Marty,
  Stephen,
  main,
  mainImgRightShape,
  iOSAppStoreImg,
  androidAppStoreImg,
  keyFeaturesLeftShape,
  keyFeaturesMainImg,
  myDabaduLeftShape,
  myDabaduImg1,
  myDabaduImg2,
  aboutUsImg1,
  aboutUsImg2,
  aboutUsImg3,
  encrypted,
  car,
  mail,
  garageHome,
  call,
  dollar,
  carCrash,
  altRoute,
  truck,
  mobile,
  ambulance,
  downloadAppMainImg,
};

export const cardsData = [
  {
    header: 'Trade In',
    content: `The essential companion for car buyers. With its cutting-edge features, including real-time car valuation,
    trade-in options, and access to OEM incentives, MyDabadu streamlines the car buying process. Leveraging
    Blackbook and auction data, it offers precise vehicle appraisal, ensuring informed decisions.`,
  },
  {
    header: 'Digital Garage',
    content: `Sales Reps can effortlessly share vehicle options, collect documents, and process payments, all in one place.
    With detailed images and feature lists for easy comparison, MyDabadu simplifies the car buying experience like
    never before.`,
  },
  {
    header: 'Your Profile Controls',
    content: `Enhanced user experience by providing personalized services. Creating a customer profile allows sales reps to
    know about your user preferences, communication channels, and recent activity. This enables the app to offer
    customized recommendations, content, and features tailored to the individual user's needs and interests.`,
  },
  {
    header: 'Credit Report',
    content: `MyDabadu provides instant access to your credit score and offers expert advice to improve it. Take control of
    your financial journey and unlock opportunities for better rates and financial stability with MyDabadu`,
  },
];
