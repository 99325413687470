import React, { useState } from "react";
import { Link } from "react-router-dom";
import { commonImages } from "views/constants/common";
import Layout from "../../components/Layout/Layout";
import JoinOurTeamModal from "../JoinOurTeamModal";

function MarketingAi() {
  const { dashAi, ai1, ai2, ai3 } = commonImages;
  const [joinTeamModal, setJointTeamModal] = useState(false);
  return (
    <Layout products={true}>
      <main className="main">
        <section className="inventory-main dabadu360-main position-relative">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h1 className="heading-1 heading-blueDark mb-3">
                  Dabadu <span>Marketing AI</span>
                </h1>
                <p className="textStyle-2">
                  Innovate marketing and drive
                  growth with the power of artificial intelligence.
                </p>

                <div className="d-flex align-items-center mt-4 mt-md-5 pt-md-5">
                  <Link to="/current-opening" className="btn btn-primary mr-4">
                    Join our team
                  </Link>
                  <button
                      className="btn btn-secondary"
                      data-toggle="modal"
                      data-target="#our-partner"
                      onClick={() => {
                        setJointTeamModal(true);
                        document.body.classList.add("modal-open");
                      }}
                    >
                      Partner with us <span className="fa-icon db-arrow-right"></span>
                  </button>
                </div>
              </div>
              <div className="col-md-8 mt-5 mt-mb-0">
                <span className="shape360-1"></span>
                <img src={dashAi} className="main-img" alt="AI Images" />
                <span className="shape360-2"></span>
              </div>
            </div>
          </div>
        </section>

        <section className="dabadu360-feature feature-columns position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="row align-items-center">
                  <div className="col-md-6 order-2 mt-4 mt-md-0 order-md-1">
                    <div className="feature-columns__block">
                      <h3 className="heading-3 heading-blueDark mb-4">
                        Generating <span className="d-block">Leads</span>
                      </h3>
                      <p className="textStyle-2">
                        You can design, execute, and analyze various marketing
                        campaigns on multiple channels under one platform.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 order-1 order-md-2">
                    <img src={ai1} className="img-fluid" alt="AI Images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="dabadu360-feature feature-columns position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-12 col-lg-10 mx-auto">
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-6">
                    <img src={ai2} className="img-fluid" alt="AI Images" />
                  </div>
                  <div className="col-md-6 col-lg-5 offset-lg-1 mt-4 mt-md-0">
                    <div className="feature-columns__block">
                      <h3 className="heading-3 heading-blueDark mb-4">
                        Reaching out{" "}
                        <span className="d-block">effectively</span>
                      </h3>
                      <p className="textStyle-2">
                        We understand how valuable it is for you to effectively contact leads. That is why we
                        offer multiple ways to reach out to them while optimizing all communications accordingly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="dabadu360-feature feature-columns position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="row align-items-center">
                  <div className="col-md-6 order-2 mt-4 mt-md-0 order-md-1">
                    <div className="feature-columns__block">
                      <h3 className="heading-3 heading-blueDark mb-4">
                        Creating Customer{" "}
                        <span className="d-block">Loyalty</span>
                      </h3>
                      <p className="textStyle-2">
                        Keeping your customers updated is key to any customer
                        relationship. You can let them know your upcoming sales
                        and promotions, or reach out to them periodically
                        for services and offers.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 order-1 order-md-2">
                    <img src={ai3} className="img-fluid" alt="AI Images" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {joinTeamModal && (
        <JoinOurTeamModal
          joinTeamModal={joinTeamModal}
          setJointTeamModal={setJointTeamModal}
        />
      )}
      {joinTeamModal && <div class="modal-backdrop fade show"></div>}
    </Layout>
  );
}

export default MarketingAi;
