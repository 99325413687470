import React from "react";
import { Link } from "react-router-dom";
import vibeatoffice1 from "../../../scss/images/vibe-at-office-1.jpg";
import vibeatoffice2 from "../../../scss/images/vibe-at-office-2.jpg";
import vibeatoffice3 from "../../../scss/images/vibe-at-office-3.jpg";
import vibeatoffice4 from "../../../scss/images/vibe-at-office-4.jpg";
import culture2 from "../../../scss/images/culture-2.jpeg";
import image1 from "../../../scss/images/image-1.png";
import image2 from "../../../scss/images/image-2.jpg";
import image4 from "../../../scss/images/image-4.jpg";
import image5 from "../../../scss/images/image-5.jpg";

import Layout from "../../components/Layout/Layout";

function LifeAtDabadu() {
  return (
    <Layout careers={true}>
      <main className="main">
        <section className="lifeDabadu-main position-relative">
          <div className="container custom-container position-relative">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-md-7">
                <div className="d-flex align-items-end flex-wrap flex-md-nowrap">
                  <div className="lifeDabadu-main__first position-relative">
                    <img src={vibeatoffice3} className="img-fluid" alt="" />
                  </div>
                  <div className="lifeDabadu-main__second position-relative mt-5 mt-md-0">
                    <img src={vibeatoffice1} className="img-fluid" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 ml-md-5 mt-5 mt-md-0">
                <div className="lifeDabadu-main__content">
                  <h1 className="heading-1 heading-blueDark mb-4">
                    The Vibe at <span>the Office</span>
                  </h1>
                  <p className="textStyle-2 mb-4">
                    We have worked hard to make the office a place where our
                    employees feel they belong. The atmosphere is relaxed and welcoming. Employees can kick back in bean bag chairs, fight it out using the office PS4 or relax in front of the large lounge windows.
                  </p>

                  <Link
                    to="/current-opening"
                    className="btn btn-primary d-inline-block"
                  >
                    Join Our Team
                  </Link>
                </div>
              </div>
              <div className="col-md-11 mx-auto mt-4 mt-5 mt-md-0">
                <div className="d-flex align-items-end justify-content-center flex-wrap flex-md-nowrap">
                  <div className="lifeDabadu-main__third position-relative">
                    <img src={vibeatoffice2} alt="" />
                  </div>
                  <div className="lifeDabadu-main__fourth position-relative mt-5">
                    <img src={vibeatoffice4} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <span className="life-at-1"></span>
          </div>
          {/* <!-- <span className="life-at-2"></span> --> */}
          <span className="life-at-3"></span>
        </section>

        <section className="user-behaviour position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-lg-7 col-md-12 mx-auto">
                <h2 className="heading-3 heading-blueDark mb-4">
                  The work <span>culture</span>
                </h2>
                <p className="textStyle-2">
                  We encourage our people to think creatively, share their ideas,
                  be resourceful, and solve problems as they arise. This is why
                  we like our employees to be self-driven, fun, creative, and
                  have a good team spirit. This open environment gives employees
                  a level of autonomy they may not have at a typical corporate
                  company.
                </p>
              </div>
            </div>
          </div>
          <span className="life-at-4"></span>
        </section>

        <section className="the-team position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-12 the-team__inner">
                <div className="row align-items-end">
                  <div className="col-md-7">
                    <div className="the-team__content">
                      <h2 className="heading-3 heading-blueDark mb-4">
                        Join <span>Us</span>
                      </h2>
                      <p className="textStyle-2">
                        We are redefining the rules of the automotive industry.
                        If you believe in the power of technology
                        and would like to work for the automotive industry, we
                        want you to join our team.
                      </p>
                      <Link
                        to="/current-opening"
                        className="btn btn-primary d-inline-block"
                      >
                        Search jobs
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-5 mt-2 mb-2 mt-md-0 mb-md-0">
                    <div className="the-team__second">
                      <img src={culture2} className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- <span className="life-at-5"></span> --> */}
          <span className="life-at-6"></span>
        </section>

        <section className="user-behaviour position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-lg-7 col-md-12 mx-auto">
                <h2 className="heading-3 heading-blueDark mb-4">
                  How Do We <span>Hire</span>
                </h2>
                <p className="textStyle-2">
                  Our hiring process is fast and simple - just like our software!
                </p>
                <p className="textStyle-2">
                  It typically consists of 2 interviews, which include a phone
                  screening with a Talent Recruiter, followed by a formal
                  interview to assess your skills and fit for the role.
                </p>
                <p className="textStyle-2">
                  We value the time of each candidate;
                  this is why our entire hiring process takes less than 10 days.
                </p>
              </div>
            </div>
          </div>
          {/* <!-- <span className="life-at-4"></span> --> */}
        </section>



        <section className="community position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-12 pb-5 text-center">
                <h2 className="heading-3 heading-blueDark">
                  Dabadu <span>Events</span>
                </h2>
              </div>
              <div className="col-md-12">
                <ul className="list-unstyled community__photos">
                  <li>
                    <img src={image5} className="img-fluid" alt="" />
                  </li>
                  <li>
                    <img src={image4} className="img-fluid" alt="" />
                  </li>
                  <li>
                    <img src={image1} className="img-fluid" alt="" />
                  </li>
                  <li>
                    <img src={image2} className="img-fluid" alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span className="story-shape-10"></span>
          <span className="story-shape-12"></span>
          <span className="story-shape-13"></span>
          <span className="story-shape-14"></span>
          <span className="story-shape-15"></span>
          <span className="story-shape-16"></span>
        </section>

        <section className="instagram-slider position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-lg-8 col-md-12 mx-auto text-center">
                <a
                  href="https://www.instagram.com/dabadu_ai/"
                  target="_blank"
                  className="follow-insta d-flex align-items-center justify-content-center"
                  rel="noreferrer"
                >
                  <span className="fa-icon db-instagram mr-4"></span>Follow Us
                  on Instagram
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default LifeAtDabadu;
