import React from 'react';
import { cardsData, commonImages } from 'views/constants/common';
import Layout from 'views/components/Layout/Layout';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Accordion from 'views/components/Accordion/Accordion';

function MyDabadu() {
  const {
    main,
    mainImgRightShape,
    iOSAppStoreImg,
    androidAppStoreImg,
    keyFeaturesLeftShape,
    keyFeaturesMainImg,
    myDabaduImg1,
    myDabaduImg2,
    aboutUsImg1,
    aboutUsImg2,
    encrypted,
    car,
    mail,
    garageHome,
    call,
    dollar,
    carCrash,
    altRoute,
    truck,
    mobile,
    ambulance,
    downloadAppMainImg,
  } = commonImages;

  const [showMore, setShowMore] = React.useState(false);

  return (
    <Layout myDabadu={true}>
      <main className='main'>
        {/* Start Welcome My Dabadu */}
        <section className='welcome-my-dabadu py-5 text-center '>
          <div className='welcome-my-dabadu__top position-relative mb-5'>
            <div className='container custom-container'>
              <div className='row'>
                <div className='col-lg-6 col-md-8 mx-auto mb-4'>
                  <h3 className='heading-3 heading-my-dabadu mb-2'>
                    Welcome to <span>MyDabadu</span>
                  </h3>
                  <p className='textStyle-2'>
                    Your MyDabadu account syncs seamlessly and in real time with the systems of dealerships partnered
                    with Dabadu, allowing you to see the same information they do regarding your application and deal.
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <img src={main} alt='Welcome to My Dabadu' className='w-100' />
                </div>
              </div>
            </div>
            <div className='welcome-my-dabadu__top__shape'>
              <img src={mainImgRightShape} alt='Welcome to My Dabadu' />
            </div>
          </div>
          <div className='container custom-container'>
            <ul className='md-download-app-links d-flex align-items-center justify-content-center list-unstyled mb-0'>
              <li>
                <a href='https://apps.apple.com/us/app/my-dabadu/id1636846346' target='_blank' rel='noreferrer'>
                  <img src={iOSAppStoreImg} alt='iOS App Store' className='img-fluid' />
                </a>
              </li>
              <li>
                <a
                  href='https://play.google.com/store/apps/details?id=com.dabaducustomerapp&hl=en_CA&gl=US'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={androidAppStoreImg} alt='Android App Store' className='img-fluid' />
                </a>
              </li>
            </ul>
          </div>
        </section>
        {/* End Welcome My Dabadu */}

        {/* Start Key Features */}
        <section className='md-key-features position-relative common-space-tb'>
          <div className='md-key-features__left-shape'>
            <img src={keyFeaturesLeftShape} alt='Key Features for Enhance Customer Control' />
          </div>
          <div className='container custom-container'>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <h3 className='heading-4 mb-5'>Key Features for Enhance Customer Control</h3>
                <img src={keyFeaturesMainImg} alt='Key Features for Enhance Customer Control' className='w-100' />
              </div>
              <div className='col-md-6 col-12'>
                <p className='textStyle-2'>
                  Being in control when buying a new car puts the customer in the driver's seat, allowing them to steer
                  towards options that align with their preferences and priorities. With control, customers can navigate
                  the complexities of the car buying process, ensuring they make choices that meet their needs and
                  preferences while maximizing their satisfaction with their purchase.
                </p>
                <Accordion cards={cardsData} />
              </div>
            </div>
          </div>
        </section>
        {/* End Key Feature */}

        {/* Start My Dabadu */}
        <section className='my-dabadu common-space-tb position-relative'>
          <div className='container custom-container'>
            <div className='row'>
              <div className='col-lg-7 col-md-8 mx-auto mb-5'>
                <div className='my-dabadu__top text-center'>
                  <h2 className='heading-2 mb-3'>My Dabadu</h2>
                  <p className='textStyle-2'>
                    Being in control when buying a new car empowers informed decisions tailored to specified needs and
                    budget. By taking charge of the purchasing process, customers can ensure they get the best value for
                    their investment, choose the features that matter most to them, and negotiate with confidence.
                    Ultimately, being in control leads to a more satisfying and rewarding car buying experience.
                  </p>
                </div>
              </div>
            </div>
            <ul className='my-dabadu__two-col-list list-unstyled mb-0'>
              <li>
                <div className='row'>
                  <div className='col-lg-6'>
                    <h3 className='heading-4 mb-5'>
                      Using your MyDabadu portal, you have instant access at your fingertips to your
                    </h3>
                    <ul className='bullet-list bullet-list--skyblue-tick list-unstyled mb-0 pl-5'>
                      <li>Bill of sale</li>
                      <li>Warranty documents</li>
                      <li>Vehicle feature list</li>
                      <li>Trade in appraisals</li>
                      <li>And all other important information about your new purchase!</li>
                    </ul>
                  </div>
                  <div className='col-lg-6'>
                    <img
                      src={myDabaduImg1}
                      alt='Using your MyDabadu portal, you have instant access at your fingertips to your'
                      className='w-100'
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className='row'>
                  <div className='col-lg-6'>
                    <h3 className='heading-4 mb-5'>But who is this 'Dabadu' anyways?</h3>
                    <p className='textStyle-2'>
                      {' '}
                      Dabadu is a Software company that caters to dealerships across North America. Dabadu has provided
                      dealerships with a way to safely and securely receive customer data and process sensitive
                      information between the customer, dealership and lender partners. And now with MyDabadu, they are
                      able to provide you with the same insights!
                    </p>
                  </div>
                  <div className='col-lg-6'>
                    <img src={myDabaduImg2} alt="But who is this 'Dabadu' anyways?" className='w-100' />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
        {/* End My Dabadu */}

        {/* Start About us */}
        <section className='md-about-us common-space-tb position-relative'>
          <div className='container custom-container'>
            <h2 className='heading-2 mb-5 text-center'>About us</h2>
            <ul className='my-dabadu__two-col-list list-unstyled mb-0'>
              <li>
                <div className='row'>
                  <div className='col-lg-6'>
                    <h3 className='heading-4 mb-5'>Personalization</h3>
                    <p className='textStyle-2'>
                      Dabadu focuses on tailoring its solutions to meet the unique needs and preferences of individual
                      users. Providing a personalized approach as it ensures that the services customers receive are
                      specifically designed to address their requirements and provide maximum value.
                    </p>
                  </div>
                  <div className='col-lg-6'>
                    <img src={aboutUsImg1} alt='Smart Home’s Smart Services' className='w-100' />
                  </div>
                </div>
              </li>
              <li>
                <div className='row'>
                  <div className='col-lg-6'>
                    <h3 className='heading-4 mb-5'>Accessibility</h3>
                    <p className='textStyle-2'>
                      Dabadu emphasizes making its technology accessible to a wide range of users, regardless of their
                      technical expertise. First time users can easily engage with Dabadu's products without
                      encountering significant barriers or requiring specialized knowledge. Whether this is your first,
                      or your fifth vehicle purchase, Dabadu wants it to be a seamless and enjoyable journey.
                    </p>
                  </div>
                  <div className='col-lg-6'>
                    <img src={aboutUsImg2} alt="Smart Home's Smart Services" className='w-100' />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
        {/* End About us */}

        {/* Start FAQ */}
        <section className='md-faq common-space-tb position-relative'>
          <div className='container custom-container'>
            <h2 className='heading-2 mb-5 text-center'>FAQ's</h2>
            <ul className={`md-faq__list list-unstyled ${showMore ? 'mb-4' : 'mb-0'}`}>
              <li>
                <div className='md-faq__list__icon'>
                  <img src={encrypted} alt='Encrypted' className='img-fluid' />
                </div>
                <div className='md-faq__list__content'>
                  <div className='md-faq__list__que'>I do not remember my password to log in to MyDabadu account</div>
                  <div className='md-faq__list__ans'>
                    On the login screen, there is a ‘forgot password’ button, if you click that, and enter your email
                    address associated with your MyDabadu account, we will send you a temporary password that will allow
                    you to log in, and change your password.
                  </div>
                </div>
              </li>
              <li>
                <div className='md-faq__list__icon'>
                  <img src={car} alt='Car' className='img-fluid' />
                </div>
                <div className='md-faq__list__content'>
                  <div className='md-faq__list__que'>I cannot remember the email used to register MyDabadu account</div>
                  <div className='md-faq__list__ans'>
                    Please contact your sales rep and they can confirm which email your account was created with
                  </div>
                </div>
              </li>
              <li>
                <div className='md-faq__list__icon'>
                  <img src={mail} alt='Mail' className='img-fluid' />
                </div>
                <div className='md-faq__list__content'>
                  <div className='md-faq__list__que'>How do I call my sales rep from the MyDabadu portal?</div>
                  <div className='md-faq__list__ans'>
                    MyDabadu provides you with your sales reps phone number, along with the phone number of the
                    dealership of your purchase for reference. You can copy and paste the phone number into your desired
                    call app to complete the call
                  </div>
                </div>
              </li>
              <li>
                <div className='md-faq__list__icon'>
                  <img src={garageHome} alt='Garage Home' className='img-fluid' />
                </div>
                <div className='md-faq__list__content'>
                  <div className='md-faq__list__que'>I’ve missed a payment on my new vehicle, how can I catch up?</div>
                  <div className='md-faq__list__ans'>
                    Dabadu doesn’t facilitate payments between customers and financial institutions. Please contact your
                    lender partner to proceed with payment matters.
                  </div>
                </div>
              </li>

              <li>
                <div className='md-faq__list__icon'>
                  <img src={call} alt='Call' className='img-fluid' />
                </div>
                <div className='md-faq__list__content'>
                  <div className='md-faq__list__que'>
                    I would like to trade in my vehicle, how can I find my appraisal value?
                  </div>
                  <div className='md-faq__list__ans'>
                    Please contact your sales rep to obtain your trade in appraisal value. Once the trade is complete,
                    your documents will be uploaded in the ‘Glove Box’ section of your MyDabadu portal
                  </div>
                </div>
              </li>

              <li>
                <div className='md-faq__list__icon'>
                  <img src={car} alt='Car' className='img-fluid' />
                </div>
                <div className='md-faq__list__content'>
                  <div className='md-faq__list__que'>
                    The vehicle details in MyDabadu Garage are not right, how can I edit them?
                  </div>
                  <div className='md-faq__list__ans'>
                    Dabadu received your vehicle and deal details from the dealership where you completed your purchase,
                    if you have concerns about those details, please contact your dealership
                  </div>
                </div>
              </li>

              <li>
                <div className='md-faq__list__icon'>
                  <img src={dollar} alt='Dollar' className='img-fluid' />
                </div>
                <div className='md-faq__list__content'>
                  <div className='md-faq__list__que'>
                    I have deferred payments on my vehicle, but it’s not reflected in MyDabadu.
                  </div>
                  <div className='md-faq__list__ans'>
                    Dabadu reflects the initial payment schedule set at time of purchase. If you have made alternate
                    arrangements with the lender partner, Dabadu would not have insights into those details.
                  </div>
                </div>
              </li>

              <li>
                <div className='md-faq__list__icon'>
                  <img src={carCrash} alt='Car Crash' className='img-fluid' />
                </div>
                <div className='md-faq__list__content'>
                  <div className='md-faq__list__que'>
                    The MyDabadu garage is missing some vehicles that I own, how can I add them?
                  </div>
                  <div className='md-faq__list__ans'>
                    Dabadu is linked to many dealerships internal systems, if you purchased a vehicle from an
                    organization outside of the Dabadu circle, then Dabadu would not have access to those details.
                  </div>
                </div>
              </li>
            </ul>
            <div className={`md-load-more-box ${showMore ? 'mt-4' : 'd-none'} `}>
              <ul className='md-faq__list list-unstyled mb-0'>
                <li>
                  <div className='md-faq__list__icon'>
                    <img src={carCrash} alt='Car Crash' className='img-fluid' />
                  </div>
                  <div className='md-faq__list__content'>
                    <div className='md-faq__list__que'>How did MyDabadu get the details of my new car?</div>
                    <div className='md-faq__list__ans'>
                      Dabadu has partnered with many dealerships across North America. Dabadu provides the consumers of
                      those dealerships with their own insights into their application, trade in and deal details.
                      Dabadu is synced with the internal systems of those dealer partners.
                    </div>
                  </div>
                </li>
                <li>
                  <div className='md-faq__list__icon'>
                    <img src={altRoute} alt='Alt Route' className='img-fluid' />
                  </div>
                  <div className='md-faq__list__content'>
                    <div className='md-faq__list__que'>
                      I am not happy with my vehicle and I would like to cancel the contract
                    </div>
                    <div className='md-faq__list__ans'>
                      In order to talk about your deal status, please contact your dealership and lender partner. Dabadu
                      does not facilitate payments or the ability to alter a contract status.
                    </div>
                  </div>
                </li>
                <li>
                  <div className='md-faq__list__icon'>
                    <img src={carCrash} alt='Car Crash' className='img-fluid' />
                  </div>
                  <div className='md-faq__list__content'>
                    <div className='md-faq__list__que'>
                      How do I access my digital application/trade-in/purchase documents?
                    </div>
                    <div className='md-faq__list__ans'>
                      <ol type='a'>
                        <li>When you log into your MyDabadu portal, you can chose the vehicle you need</li>
                        <li>
                          documents for, and scroll down to ‘GloveBox’. There will be a ‘preview ’ option, along with an
                          option to download the documents.
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='md-faq__list__icon'>
                    <img src={truck} alt='Truck' className='img-fluid' />
                  </div>
                  <div className='md-faq__list__content'>
                    <div className='md-faq__list__que'>I need service on my car, can I book one from the app?</div>
                    <div className='md-faq__list__ans'>
                      Your MyDabadu portal can help you find the information needed to contact the dealership you
                      purchased from in order to book your required service appointment
                    </div>
                  </div>
                </li>
                <li>
                  <div className='md-faq__list__icon'>
                    <img src={mobile} alt='Mobile' className='img-fluid' />
                  </div>
                  <div className='md-faq__list__content'>
                    <div className='md-faq__list__que'>How do I download the mobile MyDabadu app?</div>
                    <div className='md-faq__list__ans'>
                      MyDabadu is available on both; Android Play Store, as well as iOS App Store. Searching for
                      MyDabadu should bring you to the app for download.
                    </div>
                  </div>
                </li>
                <li>
                  <div className='md-faq__list__icon'>
                    <img src={truck} alt='Truck' className='img-fluid' />
                  </div>
                  <div className='md-faq__list__content'>
                    <div className='md-faq__list__que'>
                      I have sold my car privately, how can I transfer it in MyDabadu?
                    </div>
                    <div className='md-faq__list__ans'>
                      Since MyDabadu is linked to dealer networks, private sales cannot be transferred through the
                      application. If you purchase your next vehicle from a dealer partnered with Dabadu, you will see
                      the details appear in your MyDabadu Garage again.
                    </div>
                  </div>
                </li>
                <li>
                  <div className='md-faq__list__icon'>
                    <img src={ambulance} alt='Ambulance' className='img-fluid' />
                  </div>
                  <div className='md-faq__list__content'>
                    <div className='md-faq__list__que'>
                      I have been in an accident, can I use MyDabadu to submit an insurance claim?
                    </div>
                    <div className='md-faq__list__ans'>
                      You can access any required documents you need for your insurance claim in the MyDabadu portal,
                      however you would have to reach out to your insurance provider outside of the application.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className='md-faq__load-more text-center'>
              <button className='btn btn-primary' onClick={() => setShowMore(!showMore)}>
                {showMore ? 'Show Less' : 'Load More'}
              </button>
            </div>
          </div>
        </section>
        {/* End FAQ */}

        {/* Start FAQ */}
        <section className='md-download-app common-space-tb position-relative'>
          <div className='container custom-container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <h3 className='heading-4 mb-5'>
                  Download
                  <br />
                  App
                </h3>
                <p className='textStyle-2'>
                  Access our customer application now on both Play Store and App Store. Discover convenience at your
                  fingertips – download today and unlock a world of seamless browsing, personalized offers, and
                  effortless purchasing.
                </p>
                <ul className='md-download-app-links d-flex align-items-center justify-content-start list-unstyled mb-0'>
                  <li>
                    <a href='https://apps.apple.com/us/app/my-dabadu/id1636846346' target='_blank' rel='noreferrer'>
                      <img src={iOSAppStoreImg} alt='iOS App Store' className='img-fluid' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.dabaducustomerapp&hl=en_CA&gl=US'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img src={androidAppStoreImg} alt='Android App Store' className='img-fluid' />
                    </a>
                  </li>
                </ul>
              </div>
              <div className='col-lg-6'>
                <img src={downloadAppMainImg} alt='Download App' className='w-100' />
              </div>
            </div>
          </div>
        </section>
        {/* End FAQ */}
      </main>
    </Layout>
  );
}

export default MyDabadu;
