import React from 'react';

function JoinOurTeamModal({ joinTeamModal, setJointTeamModal }) {
  return (
    <div
      className={`modal fade popup-style-1 ${joinTeamModal && 'show'}`}
      id='our-partner'
      style={{ display: joinTeamModal ? 'block' : 'none' }}
      tabindex='-1'
      role='dialog'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <button
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
            onClick={() => {
              setJointTeamModal(false);
              document.body.classList.remove('modal-open');
            }}
          >
            <span className='fa-icon db-close'></span>
          </button>
          <div className='bg-blur'></div>
          <div className='modal-body p-0'>
            <div className='sign-up__modal'>
              <h3 className='mb-5'>Partner With Us</h3>
              <iframe
                title='Partner With Us'
                className='partner-with-us-iframe'
                src='https://share.hsforms.com/1aGkrETMrQbG_Gb8ZR7lMJwd0lqe'
              ></iframe>
              {/* <div className="sign-up__modal-form">
                <form action="#" onsubmit="return(validation())">
                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <span className="fa-icon db-user"></span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          id="First_Name"
                          required
                        />
                      </div>
                      <span id="FIRSTNAMEERR" className="text-danger"></span>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <span className="fa-icon db-user"></span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          id="Last_Name"
                          required
                        />
                      </div>
                      <span id="LASTNAMEERR" className="text-danger"></span>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <span className="fa-icon db-envelope"></span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email ID"
                          id="Email"
                        />
                      </div>
                      <span id="EMAILERR" className="text-danger"></span>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <span className="fa-icon db-phone-call"></span>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Mobile Number"
                          id="Number"
                        />
                      </div>
                      <span id="NUMBERERR" className="text-danger"></span>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <span className="fa-icon db-building"></span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company Name"
                          id="Company_Name"
                          required
                        />
                      </div>
                      <span id="NAMEERR" className="text-danger"></span>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <span className="fa-icon db-employee"></span>
                        <select
                          className="form-control"
                          id="Company_Size"
                          required
                        >
                          <option value="" disabled selected>
                            Company Size
                          </option>
                          <option>1-10 Employees</option>
                          <option>11-49 Employees</option>
                          <option>50-99 Employees</option>
                          <option>Above 100 Employees</option>
                        </select>
                      </div>
                      <span id="SIZEERR" className="text-danger"></span>
                    </div>

                    <div className="col-md-6 mx-auto">
                      <div className="form-group">
                        <span className="fa-icon db-configuration"></span>
                        <select
                          className="form-control"
                          id="Company_Dms"
                          required
                        >
                          <option value="" disabled selected>
                            Current DMS
                          </option>
                          <option>PBS System</option>
                          <option>Serti</option>
                          <option>CDK</option>
                          <option>Reynolds & Reynolds</option>
                          <option>Others</option>
                        </select>
                      </div>
                      <span id="DMSERR" className="text-danger"></span>
                    </div>

                    <div className="col-md-12 text-center mt-5">
                      <button
                        className="btn text-uppercase font-weight-bold"
                        type="submit"
                      >
                        Partner with us
                      </button>
                    </div>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinOurTeamModal;
