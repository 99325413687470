export function searchFunc() {
  var input, filter, tr, td, match, alltables;
  alltables = document.querySelectorAll("table[data-name=mytable]");
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  alltables.forEach(function (table) {
    tr = table.getElementsByTagName("tr");
    for (let i = 0; i < tr.length; i++) {
      if (!tr[i].classList.contains("header")) {
        td = tr[i].getElementsByTagName("td");
        match = false;
        for (let j = 0; j < td.length; j++) {
          if (td[j].innerHTML.toUpperCase().indexOf(filter) > -1) {
            match = true;
            break;
          }
        }
        if (!match) {
          tr[i].style.display = "none";
        } else {
          tr[i].style.display = "";
        }
      }
    }
  });
}

//function to get the current year
export function getCurrentYear() {
  return new Date().getFullYear();
}
