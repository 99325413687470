import React, { useState } from "react";
import reinventeddark from "../../../scss/images/reinvented-dark.png";
import reinventedlight from "../../../scss/images/reinvented-light.png";
import howitwork1 from "../../../scss/images/how-it-work-1.png";
import howitwork2 from "../../../scss/images/how-it-work-2.png";
import howitwork3 from "../../../scss/images/how-it-work-3.png";
import aboutgif from "../../../scss/images/about.gif";
import wholeteam from "../../../scss/images/whole-team.png";
import shareyourexcitement from "../../../scss/images/share-your-excitement.png";
import getinsight from "../../../scss/images/get-insight.png";
import icon1 from "../../../scss/images/icon-1.svg";
import icon2 from "../../../scss/images/icon-2.svg";
import icon3 from "../../../scss/images/icon-3.svg";
import icon4 from "../../../scss/images/icon-4.svg";
import icon5 from "../../../scss/images/icon-5.svg";
import icon6 from "../../../scss/images/icon-6.svg";
import icon7 from "../../../scss/images/icon-7.svg";
import icon8 from "../../../scss/images/icon-8.svg";
import icon9 from "../../../scss/images/icon-9.svg";
import enhancebuss from "../../../scss/images/enhance-buss.png";
import Layout from "../../components/Layout/Layout";
import JoinOurTeamModal from "../JoinOurTeamModal";
import { Link } from "react-router-dom";

function Home() {
  const [joinTeamModal, setJointTeamModal] = useState(false);
  return (
    <Layout products={true}>
      <main className="main">
        <section className="business-reimagined position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-12 col-lg-4 order-2 order-lg-1">
                <div className="business-reimagined__content">
                  <h1 className="heading-1 heading-blueDark mb-4">
                    Your Business Process <span>Reinvented</span>
                  </h1>
                  <p className="textStyle-2">
                    Accelerating technology disruption in the automotive market.
                  </p>

                  <div className="d-flex align-items-center mt-4 mt-md-5 pt-md-5">
                    <Link
                      to="/current-opening"
                      className="btn btn-primary mr-4"
                    >
                      Join our team
                    </Link>
                    <button
                      className="btn btn-secondary"
                      data-toggle="modal"
                      data-target="#our-partner"
                      onClick={() => {
                        setJointTeamModal(true);
                        document.body.classList.add("modal-open");
                      }}
                    >
                      Partner with us <span className="fa-icon db-arrow-right"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8 order-1 order-lg-2">
                <div className="business-reimagined__img">
                  <span className="bussi-shape-1"></span>
                  <img
                    src={reinventeddark}
                    alt=""
                    className="img-fluid dark-img"
                  />
                  <img
                    src={reinventedlight}
                    alt=""
                    className="img-fluid light-img"
                  />
                  <span className="bussi-shape-2"></span>
                </div>
              </div>
            </div>
          </div>
          <span className="bussi-shape-3"></span>
        </section>

        <section className="how-it-works text-center position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-12 pb-5">
                <h2 className="heading-3 heading-blueDark mb-4">
                  How Dabadu <span>Works</span>
                </h2>
                <p className="textStyle-2">
                  Manage your entire dealer group from one page!
                </p>
              </div>
              <div className="col-md-4">
                <div className="how-it-works__block">
                  <img src={howitwork1} alt="" />
                  <h3 className="heading-5 mb-3">Lead management</h3>
                  <p className="textStyle-1">
                    Reach out to your leads through multiple channels and track your customer engagement.
                  </p>
                  <Link to="/lead-intelligence" className="find-more">
                    Find out more >
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="how-it-works__block">
                  <img src={howitwork3} alt="" />
                  <h3 className="heading-5 mb-3">XRM</h3>
                  <p className="textStyle-1">
                    Gather customer interactions from all channels into a single
                    place and track the information that matters.
                  </p>
                  <a href="xrm" className="find-more">
                    Find out more >
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="how-it-works__block">
                  <img src={howitwork2} alt="" />
                  <h3 className="heading-5 mb-3">Marketing AI</h3>
                  <p className="textStyle-1">
                    Turn every day into Black Friday. Innovate marketing and
                    drive growth with the power of artificial intelligence.
                  </p>
                  <a href="marketing-ai" className="find-more">
                    Find out more >
                  </a>
                </div>
              </div>
            </div>
          </div>
          <span className="how-work-1"></span>
          <span className="how-work-2"></span>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-10 mx-auto text-center">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-4">
                    About <span>Dabadu</span>
                  </h2>
                  <p className="textStyle-2 mb-0">Disruptive. Powerful. Innovative.</p>
                  <p className="textStyle-2 mb-0">Dabadu is rewriting the rules for how automotive dealerships operate. Combining powerful artificial intelligence with a cutting-edge CRM, Dabadu is revolutionising the automotive industry. Our XRM does the heavy-lifting for you so that you can focus on what matters: selling. Never lose leads again or struggle with old, out-dated systems.</p>
                  <p className="textStyle-2">Join the disruption.</p>
                </div>
              </div>
              <div className="col-md-8 mx-auto mt-5">
                <div className="repeated-block__img">
                  <img src={aboutgif} alt="" className="img-fluid w-100" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6 order-2 order-md-1">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-4">
                    Incorporate Your <span>Whole Team</span>
                  </h2>
                  <p className="textStyle-2">
                    Empower your team to work more cohesively as a unit. Manage
                    the entire sales process within a single platform. Your team
                    will be amazed at how easy it is to manage an entire
                    dealership from one screen.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 order-1 order-md-2">
                <div className="repeated-block__img">
                  <img src={wholeteam} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <span className="repeat-blk-1"></span>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6 order-2 order-md-2">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-4">
                    Share the <span>Excitement</span>
                  </h2>
                  <p className="textStyle-2">
                    Your prospects should feel like family from the day they submit
                    an application. Include them in the process by showing updates in real time, and allowing them to update their profile on their own.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 order-1 order-md-1">
                <div className="repeated-block__img">
                  <img src={shareyourexcitement} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <span className="repeat-blk-2"></span>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6 order-2 order-md-1">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-4">
                    Get <span>Insight</span>
                  </h2>
                  <p className="textStyle-2">
                    Whether you manage one dealership, or you’re an ever-expanding enterprise,
                    have all the information you need in one platform. Dabadu's intuitive dashboards and interfaces display the
                    information you want to see. You'll never get lost in unnecessary details again.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 order-1 order-md-2">
                <div className="repeated-block__img">
                  <img src={getinsight} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <span className="repeat-blk-3"></span>
        </section>

        <section className="services position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="services__block d-flex">
                  <div className="services__block__img">
                    <img src={icon1} alt="" />
                  </div>

                  <div className="services__block__content">
                    <h3 className="heading-5 mb-3">XRM</h3>
                    <p className="textStyle-1">
                      Never forget an important detail when engaging with your customers. Lead details pages, notes and integrated communication tools make reaching and connecting with customers easier than ever before.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="services__block d-flex">
                  <div className="services__block__img">
                    <img src={icon2} alt="" />
                  </div>

                  <div className="services__block__content">
                    <h3 className="heading-5 mb-3">Lead Management</h3>
                    <p className="textStyle-1">
                      Easily manage all your lead-related tasks with the click of a button.
                      Distribute leads directly to the desired sales person. Request lead refunds from the comfort of your desk chair.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="services__block d-flex">
                  <div className="services__block__img">
                    <img src={icon3} alt="" />
                  </div>

                  <div className="services__block__content">
                    <h3 className="heading-5 mb-3">
                      Unlimited Real-Time Communication
                    </h3>
                    <p className="textStyle-1">
                      Instantly track and record customer communications through
                      the application.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="services__block d-flex">
                  <div className="services__block__img">
                    <img src={icon4} alt="" />
                  </div>

                  <div className="services__block__content">
                    <h3 className="heading-5 mb-3">Inventory Management</h3>
                    <p className="textStyle-1">
                      Review your entire inventory in real-time and filter based
                      on customer requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="services__block d-flex">
                  <div className="services__block__img">
                    <img src={icon5} alt="" />
                  </div>

                  <div className="services__block__content">
                    <h3 className="heading-5 mb-3">DMS Integrations </h3>
                    <p className="textStyle-1">
                      Connect to your existing DMS to see your real inventory
                      details in real time
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="services__block d-flex">
                  <div className="services__block__img">
                    <img src={icon6} alt="" />
                  </div>

                  <div className="services__block__content">
                    <h3 className="heading-5 mb-3">Lender Management</h3>
                    <p className="textStyle-1">
                      Dabadu brings Lenders and Dealers to the same table, giving dealers an option to directly submit the credit app to lenders and for lenders to respond back in real-time - making it a truly digital experience.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="services__block d-flex">
                  <div className="services__block__img">
                    <img src={icon7} alt="" />
                  </div>

                  <div className="services__block__content">
                    <h3 className="heading-5 mb-3">Warranty Management</h3>
                    <p className="textStyle-1">
                      Click once to choose a provider, a warranty package and a term - and it’s done. You now have a warranty contract drafted and ready to send off to the customer with their newly purchased vehicle.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="services__block d-flex">
                  <div className="services__block__img">
                    <img src={icon8} alt="" />
                  </div>

                  <div className="services__block__content">
                    <h3 className="heading-5 mb-3">Document Management</h3>
                    <p className="textStyle-1">
                      Create virtual dockets with ease. Dabadu's Document Management system offers users safe and secure automatic document generation. Don't worry about dotting i's and crossing t's - Dabadu's got you owl covered.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="services__block d-flex">
                  <div className="services__block__img">
                    <img src={icon9} alt="" />
                  </div>

                  <div className="services__block__content">
                    <h3 className="heading-5 mb-3">Business Intelligence</h3>
                    <p className="textStyle-1">
                      Take the guesswork out of your dealership’s performance. Make informed business decisions thanks to Dabadu’s streamlined Dashboard analytics.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6 order-2 order-md-2">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-4">
                    Let Dabadu <span>Enhance your Business</span>
                  </h2>
                  <p className="textStyle-2">
                    Dabadu eases the everyday annoyances and can answer all your
                    questions about your business in real time.{" "}
                  </p>
                  <p className="textStyle-2">
                    Dabadu has the ability to showcase you your own customers as
                    prime opportunities for renewed business{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 order-1 order-md-1">
                <div className="repeated-block__img">
                  <img src={enhancebuss} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <span className="repeat-blk-4"></span>
          {/* <span className="repeat-blk-5"></span> */}
        </section>
      </main>
      {joinTeamModal && (
        <JoinOurTeamModal
          joinTeamModal={joinTeamModal}
          setJointTeamModal={setJointTeamModal}
        />
      )}
      {joinTeamModal && <div class="modal-backdrop fade show"></div>}
    </Layout>
  );
}

export default Home;
