import React, { useState } from "react";
import { Link } from "react-router-dom";
import { commonImages } from "views/constants/common";

import Layout from "../../components/Layout/Layout";
import JoinOurTeamModal from "../JoinOurTeamModal";

function CompanyValues() {
  const {
    rocket,
    mission,
    visionMob,
    visionMobDark,
    values1,
    values2,
    values3,
    values4,
    values5,
    values6,
  } = commonImages;
  const [joinTeamModal, setJointTeamModal] = useState(false);
  return (
    <Layout aboutUs={true}>
      <main className="main">
        <section className="story-main values-main position-relative">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-4 order-2 order-md-1">
                <div className="story-main__content">
                  <h1 className="heading-1 heading-blueDark mb-4">
                    Company <span>Values</span>
                  </h1>

                  <div className="d-flex align-items-center mt-4 mt-md-5 pt-md-5">
                    <Link to="/our-story" className="btn btn-primary mr-4">
                      Our Story
                    </Link>
                    <button
                      className="btn btn-secondary"
                      data-toggle="modal"
                      data-target="#our-partner"
                      onClick={() => {
                        setJointTeamModal(true);
                        document.body.classList.add("modal-open");
                      }}
                    >
                      Partner with us <span className="fa-icon db-arrow-right"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-7 offset-md-1 order-1 order-md-2">
                <div className="story-main__img text-center">
                  <img src={rocket} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <a href id="mission">
              {""}
            </a>
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="repeated-block__img position-relative">
                  <img src={mission} alt="" className="img-fluid" />

                  <span className="values-shape-1"></span>
                </div>

                <span className="values-shape-2"></span>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-4">
                    Our <span>Mission</span>
                  </h2>
                  <p className="textStyle-2">
                    To accelerate the technological advancement and use of
                    automation in the automotive industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="repeated-block our-vision position-relative">
          <div className="container custom-container h-100">
            <div className="row align-items-center justify-content-between h-100">
              <div className="col-md-5 col-lg-5 order-2 order-md-1 h-100 mt-5 mt-md-0">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark">
                    Our <span>Vision</span>
                  </h2>
                  <p className="textStyle-2">
                    To innovate and redefine industry standards to create
                    new globally used practices in the automotive tech
                    space.
                  </p>
                </div>
              </div>
              <div className="col-md-7 col-lg-7 order-1 order-md-2">
                <div className="repeated-block__img">
                  <img src={visionMob} alt="" className="img-fluid light-img" />
                  <img
                    src={visionMobDark}
                    alt=""
                    className="img-fluid dark-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ourValues position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-10 mx-auto text-center">
                <h2 className="heading-3 heading-blueDark mb-4">
                  Our <span>Values</span>
                </h2>
              </div>

              <div className="col-md-12 mt-5">
                <ul className="list-unstyled d-flex flex-wrap ourValues__listing">
                  <li>
                    <div className="hover-card">
                      <img src={values1} className="img-fluid" alt="" />
                      <h3>D</h3>
                      <hr className="hr-line" />
                      <p className="textStyle-4">
                        Disruptiveness equals shaking up the industry with
                        innovation, which in turn equals our mission!
                      </p>
                      <span className="flip-text">D</span>
                    </div>
                  </li>
                  <li>
                    <div className="hover-card">
                      <img src={values2} className="img-fluid" alt="" />
                      <h3>A</h3>
                      <hr className="hr-line" />
                      <p className="textStyle-4">
                        Authenticity equals uniqueness and legitimacy, which
                        leads to peerless outcomes.
                      </p>
                      <span className="flip-text">A</span>
                    </div>
                  </li>
                  <li>
                    <div className="hover-card">
                      <img src={values3} className="img-fluid" alt="" />
                      <h3>B</h3>
                      <hr className="hr-line" />
                      <p className="textStyle-4">
                        Balance is the ability to move or to remain in a
                        position without losing control or falling
                      </p>
                      <span className="flip-text">B</span>
                    </div>
                  </li>
                  <li>
                    <div className="hover-card">
                      <img src={values4} className="img-fluid" alt="" />
                      <h3>A</h3>
                      <hr className="hr-line" />
                      <p className="textStyle-4">
                        Accountability equals responsibility equals ownership
                        which is the most powerful tool a team can have.
                      </p>
                      <span className="flip-text">A</span>
                    </div>
                  </li>
                  <li>
                    <div className="hover-card">
                      <img src={values5} className="img-fluid" alt="" />
                      <h3>D</h3>
                      <hr className="hr-line" />
                      <p className="textStyle-4">
                        Drive equals Passion, passion equals determination, and
                        enough determination equals success.
                      </p>
                      <span className="flip-text">D</span>
                    </div>
                  </li>
                  <li>
                    <div className="hover-card">
                      <img src={values6} className="img-fluid" alt="" />
                      <h3>U</h3>
                      <hr className="hr-line" />
                      <p className="textStyle-4">
                        Unity equals collaboration, collaboration equals
                        teamwork which can lead to new horizons.
                      </p>
                      <span className="flip-text">U</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span className="values-shape-3"></span>
          <span className="values-shape-4"></span>
        </section>
      </main>
      {joinTeamModal && (
        <JoinOurTeamModal
          joinTeamModal={joinTeamModal}
          setJointTeamModal={setJointTeamModal}
        />
      )}
      {joinTeamModal && <div class="modal-backdrop fade show"></div>}
    </Layout>
  );
}

export default CompanyValues;
