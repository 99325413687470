import React, { useState } from "react";
import xrm from "../../../scss/images/xrm.png";
import xrm1 from "../../../scss/images/xrm-1.png";
import xrm1Dark from "../../../scss/images/xrm-1-dark.png";
import xrm2 from "../../../scss/images/xrm-2.png";
import xrm2Dark from "../../../scss/images/xrm-2-dark.png";
import xrm3 from "../../../scss/images/xrm-3.png";

import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";
import JoinOurTeamModal from "../JoinOurTeamModal";

function XRM() {
  const [joinTeamModal, setJointTeamModal] = useState(false);
  return (
    <Layout products={true}>
      <main class="main">
        <section class="inventory-main xrm-main position-relative">
          <div class="container custom-container">
            <div class="row align-items-center">
              <div class="col-md-4">
                <h1 class="heading-1 heading-blueDark mb-4">
                  Dabadu <span>XRM</span>
                </h1>
                <p class="textStyle-2">
                  Simply put, Dabadu XRM is a CRM with some Xtreme
                  benefits.
                </p>

                <div class="d-flex align-items-center mt-4 mt-md-5 pt-md-5">
                  <Link to="/current-opening" class="btn btn-primary mr-4">
                    Join our team
                  </Link>
                  <button
                      className="btn btn-secondary"
                      data-toggle="modal"
                      data-target="#our-partner"
                      onClick={() => {
                        setJointTeamModal(true);
                        document.body.classList.add("modal-open");
                      }}
                    >
                      Partner with us <span className="fa-icon db-arrow-right"></span>
                  </button>
                </div>
              </div>
              <div class="col-md-8 mt-5 mb-mt-0">
                <span class="shape360-1"></span>
                <img src={xrm} class="img-fluid" alt="" />
                <span class="shape360-2"></span>
              </div>
            </div>
          </div>
        </section>

        <section class="repeated-block position-relative">
          <div class="container custom-container">
            <div class="row align-items-center">
              <div class="col-md-6 order-2 mt-4 mt-md-0 order-md-1">
                <h3 class="heading-3 heading-blueDark mb-4">
                  The Right Message at <span class="d-block">the Right Moment</span>
                </h3>
                <p class="textStyle-2">
                  Never miss any opportunity to engage with your customers.
                  Keep track of customer's birthdays and ownership anniversaries. Leave notes to easily remember customer's likes and dislikes.
                </p>
              </div>
              <div class="col-md-6 order-1 order-md-2">
                <img src={xrm1} class="img-fluid light-img" alt="" />
                <img src={xrm1Dark} class="img-fluid dark-img" alt="" />
              </div>
            </div>
          </div>
          <span class="xrm-shape-8"></span>
        </section>

        <section class="repeated-block position-relative">
          <div class="container custom-container">
            <div class="row align-items-center">
              <div class="col-md-6">
                <img src={xrm2} class="img-fluid light-img" alt="" />
                <img src={xrm2Dark} class="img-fluid dark-img" alt="" />
              </div>
              <div class="col-md-6 mt-4 mt-md-0 pl-3 pl-md-5">
                <h3 class="heading-3 heading-blueDark mb-4">
                  Bring everyone to the <span>table</span>
                </h3>
                <p class="textStyle-2">
                  From vehicle deliveries to promotional events, our XRM keeps everyone on the same page. Perfectly align even the largest of teams.
                </p>
              </div>
            </div>
          </div>
          <span class="xrm-shape-9"></span>
        </section>

        <section class="repeated-block lead-feature-second position-relative">
          <div class="container custom-container">
            <div class="row align-items-center">
              <div class="col-md-6 order-2 mt-5 mt-md-0 order-md-1">
                <div class="feature-columns__block">
                  <h3 class="heading-3 heading-blueDark mb-4">
                    Customer <span>centric</span>
                  </h3>
                  <p class="textStyle-2">
                    We care about our customer's customers. Help your customer's connect to the right team to find their dream vehicles.
                  </p>
                </div>
              </div>
              <div class="col-md-6 order-md-2 oder-1">
                <div class="feature-columns__img">
                  <img src={xrm3} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
          <span class="xrm-shape-10"></span>
        </section>
      </main>
      {joinTeamModal && (
        <JoinOurTeamModal
          joinTeamModal={joinTeamModal}
          setJointTeamModal={setJointTeamModal}
        />
      )}
      {joinTeamModal && <div class="modal-backdrop fade show"></div>}
    </Layout>
  );
}

export default XRM;
