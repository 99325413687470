import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";

function Layout({ children, products, aboutUs, careers, resources, myDabadu }) {
  return (
    <>
      <div id="overlay2">
        <span id="mode2"></span>
      </div>
      <div className="wrapper">
        <Header
          products={products}
          aboutUs={aboutUs}
          careers={careers}
          resources={resources}
          myDabadu={myDabadu}
        />
        {children}
        <Footer />
      </div>
    </>
  );
}

export default Layout;
