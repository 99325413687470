import React from "react";
import { commonImages } from "views/constants/common";

import Layout from "views/components/Layout/Layout";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function OurStory() {
  const {
    story1,
    story2,
    story3,
    story4,
    story5,
    culture1,
    culture2,
    culture3,
    culture4,
    culture5,
    culture6,
    culture7,
    culture8,
  } = commonImages;
  return (
    <Layout aboutUs={true}>
      <main className="main">
        <section className="story-main position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-4">
                <div className="story-main__content">
                  <h1 className="heading-1 heading-blueDark mb-4">
                    Our <span>Story</span>
                  </h1>
                  <p className="textStyle-2">
                    Dabadu started with a simple mission- make it possible for dealerships to sell cars faster and efficiently. We’re on
                    track to achieve this mission. Check out how we got here
                    below.
                  </p>

                  <div className="d-flex align-items-center mt-4 mt-md-5 pt-md-5">
                    <a
                      href="/company-values#mission"
                      className="btn btn-primary mr-4"
                    >
                      Mission
                    </a>
                    <a href="#culture" className="btn btn-secondary">
                      Culture
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span className="story-shape-2"></span>
          <span className="story-shape-3"></span>
        </section>

        <section className="repeated-block about-us position-relative mb-5 pb-5">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-5 col-lg-6 order-2 order-md-2">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-5">
                    Hatching the <span>Egg</span>
                  </h2>
                  <p className="textStyle-2">
                    It started with a desire to innovate the automotive
                    industry.
                  </p>
                  <p className="textStyle-2">
                    The goal was to revolutionize the traditional, old-fashioned
                    sales process by providing a more efficient, secure, and
                    sustainable process, which speeds up the sales process
                    exponentially.
                  </p>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 order-1 order-md-1">
                <div className="repeated-block__img">
                  <img src={story1} alt="StoryPic" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <span className="story-shape-4"></span>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-5 col-lg-6 order-2 order-md-1">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-5">
                    Taking <span>Flight</span>
                  </h2>
                  <p className="textStyle-2">
                    In the world of online shopping,
                    the automotive industry uses the traditional
                    business approach. The businesses that did try to innovate,
                    ended up using 10+ third-party software and tools that do
                    not correlate well together, Instead of becoming more efficient, dealer groups ended up with a
                    complex process that further slowed down company operations.
                  </p>
                  <p className="textStyle-2">
                    This is where we plan to help dealership groups.
                  </p>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 order-1 order-md-2">
                <div className="repeated-block__img">
                  <img src={story2} alt="StoryPic" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <span className="story-shape-8"></span>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-5 col-lg-6 order-2 order-md-2">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-5">
                    Building the <span>nest</span>
                  </h2>
                  <p className="textStyle-2">
                    The idea of Dabadu was born in late 2020 in the midst of a
                    global pandemic when dealerships around the globe were
                    closing their doors. Many sales agents were left without work for
                    many months. Many customers were left without access to vehicles.
                  </p>
                  <p className="textStyle-2">
                    Dabadu got its name from a brainstorming session over a
                    team-building lunch. The crumpled napkin
                    where the name was first scribbled can be seen framed in the
                    office today!
                  </p>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 order-1 order-md-1">
                <div className="repeated-block__img">
                  <img src={story3} alt="StoryPic" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <span className="story-shape-9"></span>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-5 col-lg-6 order-2 order-md-1">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-5">
                    Touching the <span>Sky</span>
                  </h2>
                  <p className="textStyle-2">
                    We have recently acquired a new building in Kitchener. We have also started
                    hiring new talent at various levels.
                  </p>
                  <p className="textStyle-2">
                    Our series of products are in the beta phase and are going
                    through rigorous testing in the real world through our
                    partnered clients.
                  </p>
                  <p className="textStyle-2">
                    Once out in the market, Dabadu will revolutionize the automotive industry.
                  </p>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 order-1 order-md-2">
                <div className="repeated-block__img">
                  <img src={story4} alt="StoryPic" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <span className="story-shape-8"></span>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-5 col-lg-6 order-2 order-md-2">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-5">
                    Flying to <span>New Heights</span>
                  </h2>
                  <p className="textStyle-2">
                    Dabadu is driven to create a future where the sales process
                    is so seamless, anyone can do it - anywhere. Through
                    innovation and automation, a customer will have a new
                    vehicle delivered to their door within hours of applying -
                    without ever having to leave their couch! What does that
                    mean for the sales teams? More sales and more happy customers!
                  </p>
                </div>
              </div>
              <div className="col-md-7 col-lg-6 order-1 order-md-1">
                <div className="repeated-block__img">
                  <img src={story5} alt="StoryPic" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <span className="story-shape-9"></span>
        </section>

        <section className="our-culture position-relative mt-5 pt-5 mb-5 pb-5">
          <div className="container custom-container">
            <a id="culture" href>
              {""}
            </a>
            <div className="row">
              <div className="col-md-12">
                <Carousel
                  className="culture-slider"
                  showThumbs={false}
                  autoPlay={true}
                  swipeable={true}
                  showArrows={false}
                  showStatus={false}
                  infiniteLoop={true}
                  stopOnHover={false}
                >
                  <div>
                    <img
                      src={culture1}
                      className="img-fluid"
                      alt="culturePic"
                    />
                  </div>
                  <div>
                    <img
                      src={culture2}
                      className="img-fluid"
                      alt="culturePic"
                    />
                  </div>
                  <div>
                    <img
                      src={culture3}
                      className="img-fluid"
                      alt="culturePic"
                    />
                  </div>
                  <div>
                    <img
                      src={culture4}
                      className="img-fluid"
                      alt="culturePic"
                    />
                  </div>
                  <div>
                    <img
                      src={culture5}
                      className="img-fluid"
                      alt="culturePic"
                    />
                  </div>
                  <div>
                    <img
                      src={culture6}
                      className="img-fluid"
                      alt="culturePic"
                    />
                  </div>
                  <div>
                    <img
                      src={culture7}
                      className="img-fluid"
                      alt="culturePic"
                    />
                  </div>
                  <div>
                    <img
                      src={culture8}
                      className="img-fluid"
                      alt="culturePic"
                    />
                  </div>
                </Carousel>
                <div className="culture_block">
                  <div className="culture_block__content">
                    <h2 className="heading-3 heading-blueDark mb-4">
                      Loving the Nest
                    </h2>
                    <p className="textStyle-2 mb-3">
                      Dabadu has designed a culture that thrives on both
                      innovation and balance. We encourage our team to push the
                      limits in their work and in their own lives. In a new company, the impact of every team member’s
                      attitude and productivity has a profound impact. We have
                      designed an environment to foster creativity, innovation and originality. We have built our
                      foundational team with a diverse group to instill
                      creativity, passion and individualism into the workplace
                      and into our products! We believe everyone has great ideas,
                      and we surround ourselves with those who are willing to
                      take those great ideas and mold them into incredible
                      ideas!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span className="story-shape-7"></span>
        </section>
      </main>
    </Layout>
  );
}

export default OurStory;
