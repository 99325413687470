import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import CompanyValues from "./views/containers/Companyvalues/CompanyValues";
import CurrentOpening from "./views/containers/CurrentOpening/CurrentOpening";
import DabaduNest from "./views/containers/DabaduNest/DabaduNest";
import Download from "./views/containers/Download/Download";
import Home from "./views/containers/Home/Home";
import InventoryManagement from "./views/containers/InventoryManagement/InventoryManagement";
import LeadManagement from "./views/containers/LeadManagement/LeadManagement";
import LifeAtDabadu from "./views/containers/LifeAtDabadu/LifeAtDabadu";
import MarketingAi from "./views/containers/MarketingAi/MarketingAi";
import OurStory from "./views/containers/OurStory/OurStory";
import OurTeam from "./views/containers/OurTeam/OurTeam";
import XRM from "./views/containers/XRM/XRM";
import PrivacyPolicy from "./views/containers/PrivacyPolicy/PrivacyPolicy";
import MyDabadu from "views/containers/MyDabadu/MyDabadu";

function App() {
  localStorage.setItem("darkmode", false);
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/download" element={<Download />} />
        <Route exact path="/our-team" element={<OurTeam />} />
        <Route
          exact
          path="/inventory-management"
          element={<InventoryManagement />}
        />
        <Route exact path="/company-values" element={<CompanyValues />} />
        <Route exact path="/xrm" element={<XRM />} />
        <Route exact path="/our-story" element={<OurStory />} />
        <Route exact path="/marketing-ai" element={<MarketingAi />} />
        <Route exact path="/current-opening" element={<CurrentOpening />} />
        <Route exact path="/dabadu-nest" element={<DabaduNest />} />
        <Route exact path="/lead-intelligence" element={<LeadManagement />} />
        <Route exact path="/life-at-dabadu" element={<LifeAtDabadu />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route exact path="/my-dabadu" element={<MyDabadu/>}/>
      </Routes>
    </Router>
  );
}

export default App;
