import React, { useState } from "react";
import { Link } from "react-router-dom";
import dlead from "../../../scss/images/d-lead.png";
import lead1 from "../../../scss/images/lead-1.png";
import lead1Dark from "../../../scss/images/lead-1-dark.png"
import lead2 from "../../../scss/images/lead-2.png";
import lead3 from "../../../scss/images/lead-3.png";

import Layout from "../../components/Layout/Layout";
import JoinOurTeamModal from "../JoinOurTeamModal";

function LeadManagement() {
  const [joinTeamModal, setJointTeamModal] = useState(false);
  return (
    <Layout products={true}>
      <main className="main">
        <section className="inventory-main lead-main position-relative">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <h1 className="heading-1 heading-blueDark mb-4">
                  Dabadu <br />
                  <span>Lead Intelligence</span>
                </h1>
                <p className="textStyle-2">
                  Go beyond analyzing and nurturing leads. Step up your lead
                  management game with artificial intelligence.
                </p>

                <div className="d-flex align-items-center mt-4 mt-md-5 pt-md-5">
                  <Link to="/current-opening" className="btn btn-primary mr-4">
                    Join our team
                  </Link>
                  <button
                      className="btn btn-secondary"
                      data-toggle="modal"
                      data-target="#our-partner"
                      onClick={() => {
                        setJointTeamModal(true);
                        document.body.classList.add("modal-open");
                      }}
                    >
                      Partner with us <span className="fa-icon db-arrow-right"></span>
                  </button>
                </div>
              </div>
              <div className="col-md-7 mt-5 mb-mt-0">
                <img src={dlead} className="main-img" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="repeated-block lead-feature-first position-relative">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="feature-columns__img">
                  <img src={lead1} class="img-fluid light-img" alt="" />
                  <img src={lead1Dark} class="img-fluid dark-img" alt="" />
                </div>
              </div>
              <div className="col-md-6 mt-5 mt-md-0">
                <div className="feature-columns__block">
                  <h3 className="heading-3 heading-blueDark mb-4">
                    100 sources, <span>1 system</span>
                  </h3>
                  <p className="textStyle-2">
                    Generate new leads from diverse platforms while ensuring that no leads fall through the cracks. Our lead management system
                    will help you make the most of customers who are interested
                    in your product or service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="repeated-block lead-feature-second position-relative">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 mt-5 mt-md-0 order-md-1">
                <div className="feature-columns__block">
                  <h3 className="heading-3 heading-blueDark mb-4">
                    Real-time <span>Reliance</span>
                  </h3>
                  <p className="textStyle-2">
                    Go beyond communication management! You need to see more than just past
                    communication to make the most of your sales calls.
                  </p>
                  <p className="textStyle-2">
                    Get real-time assistance to tailor your pitch while
                    communicating with the customers.
                  </p>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <div className="feature-columns__img">
                  <img src={lead2} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="repeated-block lead-feature-third position-relative">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="feature-columns__img">
                  <img src={lead3} className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-md-6 mt-5 mt-md-0">
                <div className="feature-columns__block">
                  <h3 className="heading-3 heading-blueDark mb-4">
                    Long-term <span>Customer Relationship</span>
                  </h3>
                  <p className="textStyle-2">
                    Business is not a one-time transaction. Create a
                    valuable relationship with your customers even after the sale- for
                    services, upcoming sales, or even cross-selling.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {joinTeamModal && (
        <JoinOurTeamModal
          joinTeamModal={joinTeamModal}
          setJointTeamModal={setJointTeamModal}
        />
      )}
      {joinTeamModal && <div class="modal-backdrop fade show"></div>}
    </Layout>
  );
}

export default LeadManagement;
