import React, { useState } from 'react';

const Accordion = ({ cards }) => {
  const [expandedIndices, setExpandedIndices] = useState([0]);

  const toggleAccordion = (index) => {
    const currentIndex = expandedIndices.indexOf(index);
    if (currentIndex === -1) {
      setExpandedIndices([...expandedIndices, index]);
    } else {
      setExpandedIndices(expandedIndices.filter((i) => i !== index));
    }
  };

  return (
    <div className='accordion' id='accordionExample'>
      {cards.map((card, index) => (
        <div className={`card ${expandedIndices.includes(index) ? 'open' : ''}`} key={index}>
          <div className='card-header' id={`heading${index}`}>
            <h5 className='mb-0'>
              <button
                className='btn btn-link'
                type='button'
                onClick={() => toggleAccordion(index)}
                aria-expanded={expandedIndices.includes(index)}
                aria-controls={`collapse${index}`}
              >
                {card.header}
                <span className={`arrow ${expandedIndices.includes(index) ? 'open' : ''}`}></span>
              </button>
            </h5>
          </div>

          <div
            id={`collapse${index}`}
            className={`collapse ${expandedIndices.includes(index) ? 'show' : ''}`}
            aria-labelledby={`heading${index}`}
            data-parent='#accordionExample'
          >
            <div className='card-body'>
              <p className='textStyle-2'>{card.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
