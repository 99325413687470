import React, { useState } from "react";
import nest from "../../../scss/images/nest.png";
import nestDark from "../../../scss/images/nest-dark.png";
import nest1 from "../../../scss/images/nest-1.png";
import nest2 from "../../../scss/images/nest-2.png";
import nest3 from "../../../scss/images/nest-3.png";
import nest4 from "../../../scss/images/nest-4.png";

import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";
import JoinOurTeamModal from "../JoinOurTeamModal";

function DabaduNest() {
  const [joinTeamModal, setJointTeamModal] = useState(false);
  return (
    <Layout products={true}>
      <main className="main">
        <section className="inventory-main dabadu360-main position-relative">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h1 className="heading-1 heading-blueDark mb-4">
                  Dabadu <span>Nest</span>
                </h1>
                <p className="textStyle-2">
                  A single user-friendly platform to replace 15+ dealership
                  tools.
                </p>

                <div className="d-flex align-items-center mt-4 mt-md-5 pt-md-5">
                  <Link to="/current-opening" className="btn btn-primary mr-4">
                    Join our team
                  </Link>
                  <button
                      className="btn btn-secondary"
                      data-toggle="modal"
                      data-target="#our-partner"
                      onClick={() => {
                        setJointTeamModal(true);
                        document.body.classList.add("modal-open");
                      }}
                    >
                      Partner with us <span className="fa-icon db-arrow-right"></span>
                  </button>
                </div>
              </div>
              <div className="col-md-8 mt-5 mt-mb-0">
                <span className="shape360-1"></span>
                <img src={nest} className="img-fluid light-img" alt="" />
                <img src={nestDark} className="img-fluid dark-img" alt="" />
                <span className="shape360-2"></span>
              </div>
            </div>
          </div>
          <span className="shape360-3"></span>
        </section>

        <section className="dabadu360-work position-relative text-center">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-10 mx-auto mb-4">
                <h2 className="heading-3 heading-blueDark mb-4">
                  What makes Dabadu <span>special?</span>
                </h2>
                <p className="textStyle-2">
                  Our goal is to make your business as smooth as possible. We
                  know the struggle of juggling multiple tools to close a sale.
                  Dabadu Nest, an end-to-end dealer group
                  software solution, will replace 15+ such tools needed
                  to make a successful deal.
                </p>
              </div>
              <div className="col-md-10 mx-auto">
                <img src={nest4} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
          <span className="shape360-4"></span>
          <span className="shape360-5"></span>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 mt-4 mt-md-0 order-md-1">
                <h3 className="heading-3 heading-blueDark mb-5">
                  Pre-sale <span>Benefits</span>
                </h3>
                <p className="textStyle-2">
                  From generating leads through various channels to managing them
                  using artificial intelligence, our system will help you identify new business
                  opportunities fast, without having to verify details anywhere
                  else.
                </p>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <img src={nest1} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
          <span className="shape360-6"></span>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-6">
                <img src={nest2} className="img-fluid" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 mt-4 mt-md-0">
                <h3 className="heading-3 heading-blueDark mb-5">
                  During-sale <span>Benefits</span>
                </h3>
                <p className="textStyle-2">
                  From managing customer documents and scheduling meetings to
                  engaging with customers through virtual calls, everything happens in one
                  place. Review all customer information at a glance, for
                  seamless and personalized communication.
                </p>
              </div>
            </div>
          </div>
          <span className="shape360-7"></span>
        </section>

        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 mt-4 mt-md-0 order-md-1">
                <h3 className="heading-3 heading-blueDark mb-5">
                  Post-sale <span>Benefits</span>
                </h3>
                <p className="textStyle-2">
                  Automate standard communication tasks for stronger customer
                  relationships. Ensure satisfaction by engaging with customers for delivery, service,
                  post-sales support, and renewals.
                </p>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <img src={nest3} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
      </main>
      {joinTeamModal && (
        <JoinOurTeamModal
          joinTeamModal={joinTeamModal}
          setJointTeamModal={setJointTeamModal}
        />
      )}
      {joinTeamModal && <div class="modal-backdrop fade show"></div>}
    </Layout>
  );
}

export default DabaduNest;
