import React from "react";
import { commonImages } from "views/constants/common";
import Layout from "views/components/Layout/Layout";

function Download() {
  const { googlePlay, appStore, download } = commonImages;
  return (
    <Layout resources={true}>
      <main className="main">
        <section className="repeated-block position-relative">
          <div className="container custom-container">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-md-10 mx-auto mb-md-5 pb-md-5 text-center">
                <h1 className="heading-1 heading-blueDark mb-3">
                  Download <span>Center</span>
                </h1>
              </div>
              <div className="col-md-6 col-lg-6 order-2 order-md-2">
                <div className="repeated-block__content">
                  <h2 className="heading-3 heading-blueDark mb-4">
                    Download <span>Our App</span>
                  </h2>
                  <p className="textStyle-2">
                    Empower your team to work as a cohesive unit. Manage the entire sales process within a single platform. Your team will be amazed at how easy it is to manage an entire dealership from one app.
                  </p>

                  <ul className="list-unstyled d-flex align-items-center download-link mt-md-5 pt-md-5 mb-0">
                    <li>
                      <a
                        href="https://apps.apple.com/us/app/dabadu/id1564053973"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={appStore} alt="App Store" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.dabadu.auto&amp;hl=en&amp;gl=US"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={googlePlay} alt="Google Play" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 order-1 order-md-1">
                <div className="repeated-block__img">
                  <img src={download} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default Download;
