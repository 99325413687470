import React from 'react';
import Layout from 'views/components/Layout/Layout';
import { commonImages } from 'views/constants/common';

function OurTeam() {
  const { Bryanne, Pulkit, Umang, linkedIn, Rohit, Stephen } = commonImages;

  return (
    <Layout aboutUs={true}>
      <main className='main'>
        <section className='ourTeam-heading position-relative'>
          <div className='container custom-container'>
            <div className='row'>
              <div className='col-md-12 col-lg-6 mx-auto text-center'>
                <div className='ourTeam-heading__content'>
                  <h2 className='heading-1 heading-blueDark mb-4'>
                    Coming onboard means <span>joining the family!</span>
                  </h2>
                  <p className='textStyle-2'>Our team brings a wealth of experience from diverse domains.</p>
                </div>
              </div>
            </div>
          </div>
          <span className='team-shape-1'></span>
          <span className='team-shape-2'></span>
          <span className='team-shape-4'></span>
        </section>

        <section className='ourTeam-member position-relative'>
          <div className='container custom-container'>
            <div className='row'>
              <div className='col-md-12 mx-auto text-center'>
                <div className='ourTeam-member__content'>
                  <ul className='list-unstyled d-flex align-items-center justify-content-center member-listing flex-wrap'>
                    <li>
                      <div className='member-block'>
                        <img src={Pulkit} className='img-fluid' alt='Pulkit' />
                        <div className='linkedin'>
                          <a href='https://www.linkedin.com/in/arorapulkit/' target='_blank' rel='noreferrer'>
                            <img src={linkedIn} alt='Linkedin' />
                          </a>
                        </div>
                        <div className='member-block-content'>
                          <h4 className='member-name'>Pulkit Arora</h4>
                          <span class='member-desg'>Founder & CEO</span>
                          <hr />
                          {/* <p className="textStyle-2 member-desc mb-2">
                            CEO by profession, a golf player by passion!
                          </p> */}
                          <p className='textStyle-2 member-desc mb-0'>
                            {/* Everything we do is led by his laying the
                            foundations on which we build all our work. */}
                            He puts the fun in fundamentals and lives at the top of the leader board in office minigolf.
                            He believes weekends are for hiking with friends and learning about the latest and greatest
                            tech. But no matter what, he is always ready for that "Suit up" phone call.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='member-block'>
                        <img src={Bryanne} className='img-fluid' alt='Bryanne' />
                        <div className='linkedin'>
                          <a
                            href='https://www.linkedin.com/in/bryanne-goad-00a476106/?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABrD74oBna0qIYMiHjW7Q5zP1ngd9DStsQY'
                            target='_blank'
                            rel='noreferrer'
                          >
                            <img src={linkedIn} alt='Linkedin' />
                          </a>
                        </div>
                        <div className='member-block-content'>
                          <h4 className='member-name'>Bryanne Goad</h4>
                          <span class='member-desg'>Product Manager</span>
                          <hr />
                          <p className='textStyle-2 member-desc mb-0'>
                            The girl with more plants on her desk than pens, lives for the sunshine and a good vanilla
                            latte on the rocks, hates cold pizza, refuses to use an ATM for the lack of personability.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='member-block'>
                        <img src={Umang} className='img-fluid' alt='Umang' />
                        <div className='linkedin'>
                          <a
                            href='https://www.linkedin.com/in/umang-revari-8a1866172/'
                            target='_blank'
                            rel='noreferrer'
                          >
                            <img src={linkedIn} alt='Linkedin' />
                          </a>
                        </div>
                        <div className='member-block-content'>
                          <h4 className='member-name'>Umang Revari</h4>
                          <span class='member-desg'>Marketing Manager</span>
                          <hr />
                          <p className='textStyle-2 member-desc mb-0'>
                            He is a professional attention seeker or the marketing guy! The only thing common between
                            him and a hippie is the love for organic (engagement)
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='member-block'>
                        <img src={Rohit} className='img-fluid' alt='Rohit' />
                        <div className='linkedin'>
                          <a href='https://www.linkedin.com/in/rhtluthra/' target='_blank' rel='noreferrer'>
                            <img src={linkedIn} alt='Linkedin' />
                          </a>
                        </div>
                        <div className='member-block-content'>
                          <h4 className='member-name'>Rohit Luthra</h4>
                          <span class='member-desg'>Customer Success Manager</span>
                          <hr />
                          <p className='textStyle-2 member-desc mb-0'>
                            Iced coffee over hot coffee, any day- any time. Every day I celebrate our Customer’s wins;
                            whether that’ s new one or a legacy partner hitting new targets - their success is what
                            drives me. Love to travel and learn about new cultures.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className='member-block'>
                        <img src={Stephen} className='img-fluid' alt='Stephen' />
                        <div className='linkedin'>
                          <a href='https://www.linkedin.com/in/sirscampbell/' target='_blank' rel='noreferrer'>
                            <img src={linkedIn} alt='Linkedin' />
                          </a>
                        </div>
                        <div className='member-block-content'>
                          <h4 className='member-name'>Stephen Campbell</h4>
                          <span class='member-desg'>Customer Success Manager</span>
                          <hr />
                          <p className='textStyle-2 member-desc mb-0'>
                            He loves asking questions, enjoying great conversations, learning new things and laughing
                            every day. Not afraid to put pineapple on a pizza and happiest when on the disc golf course.
                            Anyone up for a round?
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul className='list-unstyled d-flex align-items-center justify-content-center flex-wrap mobile-member'></ul>
                </div>
              </div>
            </div>
          </div>
          <span className='member-shape-1'></span>
        </section>
      </main>
    </Layout>
  );
}

export default OurTeam;
