import React from "react";
import { Link } from "react-router-dom";
import { commonImages } from "views/constants/common";
import { getCurrentYear } from "views/constants/helper";

function Footer() {
  const { appStore, googlePlay, logo } = commonImages;
  return (
    <footer className="footer position-relative">
      <div className="footer__top">
        <div className="custom-container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <div className="footer__top-content">
                <img src={logo} alt="" className="img-fluid" />

                <p className="textStyle-3 mb-0">
                  Accelerating Technology Disruption in the Automotive Market.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <ul className="list-unstyled d-flex align-items-center justify-content-end download-link mb-0">
                <li>
                  <a
                    href="https://apps.apple.com/us/app/dabadu/id1564053973"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appStore} alt="App Store" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.dabadu.auto&hl=en&gl=US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={googlePlay} alt="Google Play" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__middle">
        <div className="custom-container">
          <div className="row">
            <div className="col-6 col-sm-6 col-md-3">
              <div className="footer__middle-menu">
                <h3>Company</h3>
                <ul className="list-unstyled mb-0">
                  <li>
                    <Link to="/our-story">About Us</Link>
                  </li>
                  {/* <li><a href="#">Press</a></li>  */}
                  <li>
                    <Link to="/current-opening">Careers</Link>
                  </li>
                  {/* <li><a href="#">Terms of Service</a></li> */}
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div className="footer__middle-menu">
                <h3>Resources</h3>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="https://blog.dabadu.ai" target="_blank" rel="noreferrer">Blog</a>
                  </li>
                  <li>
                    <a
                      href="https://release.dabadu.ai/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      What's New
                    </a>
                  </li>
                  {/* <li>
                    <a href>System Status</a>
                  </li> */}
                  {/* <li><a href="#">Live Demo + Q&A</a></li>  */}
                  <li>
                    <Link to="/download">Downloads</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div className="footer__middle-menu">
                <h3>Contact Us</h3>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="mailto:info@dabadu.ai">Email Us</a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/dabadu-ai/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/Dabadu_ai"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/dabadu_ai/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div className="footer__middle-menu">
                <h3>Locations</h3>
                <ul className="list-unstyled mb-0">
                  <li>Suit 130, 4275 King St E, Kitchener, ON N2P 2E9</li>
                  <li>
                    <a href="tel:1-844-605-0101">1-844-605-0101</a>
                  </li>
                  <li>
                    <a href="mailto:info@dabadu.ai">info@dabadu.ai</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="custom-container">
          <div className="row">
            <div className="col-md-12 text-center">
              <p>Copyright © {getCurrentYear()} dabadu.ai. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
