import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { commonImages } from 'views/constants/common';
import JoinOurTeamModal from '../../containers/JoinOurTeamModal';

function Header({ products, aboutUs, careers, resources, myDabadu }) {
  const [darkTheme, setDarkTheme] = useState(false);
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const [joinTeamModal, setJointTeamModal] = useState(false);
  const [navItemProduct, setNavItemProduct] = useState(false);
  const [navItemAboutUs, setNavItemAboutUs] = useState(false);
  const [navItemCareers, setNavItemCareers] = useState(false);
  const [navItemResources, setNavItemResources] = useState(false);
  const [navItemMyDabadu, setNavItemMyDabadu] = useState(false);

  const text2 = '/darkmode.exe';
  const chars2 = text2.split('');
  const { logo } = commonImages;

  let j = 0;

  function data2() {
    document.body.classList.add('dark-theme', 'overlay-open');
    document.getElementById('overlay2').style.display = 'block';
    localStorage.setItem('darkmode', true);
    setDarkTheme(true);
    const mode2 = document.getElementById('mode2');
    setInterval(function () {
      if (j < chars2.length) {
        mode2.innerHTML += chars2[j++];
      } else {
        j = 0;
        mode2.innerHTML = '';
        document.body.classList.remove('overlay-open');
        document.querySelector('#overlay2').style.display = 'none';
      }
    }, 220);
  }

  useEffect(() => {
    setDarkTheme(JSON.parse(localStorage.getItem('darkmode')));
  }, [darkTheme]);

  return (
    <header className='header'>
      <nav className='navbar custom-container'>
        <Link className='navbar-brand' to='/'>
          <img src={logo} className='img-fluid' alt='' />
        </Link>
        <div className='navbar-menu'>
          <div className={`navbar-menu-left ${toggleNavbar ? 'menu-open' : ''}`}>
            <span className='db-close fa-icon' onClick={() => setToggleNavbar(false)}></span>
            <ul className='navbar-nav'>
              <li className={`nav-item dropdown ${products ? 'active' : ''} ${navItemProduct ? 'show' : ''}`}>
                <a
                  href={'/'}
                  className='nav-link dropdown-toggle'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  onClick={() => setNavItemProduct(!navItemProduct)}
                >
                  Products
                </a>
                <div className={`dropdown-menu ${navItemProduct && 'show'}`} aria-labelledby='dropdownMenuButton'>
                  <Link className='dropdown-item' to='/dabadu-nest'>
                    Dabadu Nest
                  </Link>
                  <Link className='dropdown-item' to='/xrm'>
                    Dabadu XRM
                  </Link>
                  <Link className='dropdown-item' to='/lead-intelligence'>
                    Dabadu Lead Intelligence
                  </Link>
                  <Link className='dropdown-item' to='/inventory-management'>
                    Dabadu Inventory Management
                  </Link>
                  {/* <a className="dropdown-item" href="#">Dabadu Envision</a> --> */}
                  <Link className='dropdown-item' to='/marketing-ai'>
                    Dabadu Marketing AI
                  </Link>
                  {/* <a className="dropdown-item" href="#">Dabadu Warranty Management</a> */}
                  {/* <a className="dropdown-item" href="#">Dabadu Document Management</a> */}
                </div>
              </li>
              <li className={`nav-item dropdown ${aboutUs ? 'active' : ''} ${navItemAboutUs && 'show'}`}>
                <a
                  href='/'
                  className='nav-link dropdown-toggle'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  onClick={() => setNavItemAboutUs(!navItemAboutUs)}
                >
                  About Us
                </a>
                <div className={`dropdown-menu ${navItemAboutUs && 'show'}`} aria-labelledby='dropdownMenuButton'>
                  <Link className='dropdown-item' to='/our-team'>
                    Our Team
                  </Link>
                  <Link className='dropdown-item' to='/our-story'>
                    Our Story
                  </Link>
                  <Link className='dropdown-item' to='/company-values'>
                    Company Values
                  </Link>
                </div>
              </li>
              <li className={`nav-item dropdown ${careers ? 'active' : ''} ${navItemCareers && 'show'}`}>
                <a
                  href='/'
                  className='nav-link dropdown-toggle'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  onClick={() => setNavItemCareers(!navItemCareers)}
                >
                  Careers
                </a>
                <div className={`dropdown-menu ${navItemCareers && 'show'}`} aria-labelledby='dropdownMenuButton'>
                  <Link className='dropdown-item' to='/life-at-dabadu'>
                    Life at Dabadu
                  </Link>
                  {/* <a className="dropdown-item" href="#">News</a> */}
                  <Link className='dropdown-item' to='/current-opening'>
                    Current Openings
                  </Link>
                </div>
              </li>
              <li className={`nav-item dropdown ${resources ? 'active' : ' '} ${navItemResources && 'show'}`}>
                <a
                  href='/'
                  className='nav-link dropdown-toggle'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  onClick={() => setNavItemResources(!navItemResources)}
                >
                  Resources
                </a>
                <div className={`dropdown-menu ${navItemResources && 'show'}`} aria-labelledby='dropdownMenuButton'>
                  <a className='dropdown-item' href='https://blog.dabadu.ai' target='_blank' rel='noreferrer'>
                    Blog
                  </a>
                  <a className='dropdown-item' href='https://release.dabadu.ai/' target='_blank' rel='noreferrer'>
                    What's New
                  </a>
                  {/* <a className="dropdown-item" href>
                    System Status
                  </a> */}
                  <Link className='dropdown-item' to='/download'>
                    Downloads
                  </Link>
                </div>
              </li>

              <li className={`nav-item dropdown ${myDabadu ? 'active' : ' '} ${navItemMyDabadu && 'show'}`}>
                <a
                  href='/my-dabadu'
                  className='nav-link dropdown-toggle'
                  id='dropdownMenuButton'
                  data-toggle='dropdown'
                  onClick={() => setNavItemMyDabadu(!navItemMyDabadu)}
                >
                  MyDabadu
                </a>
              </li>
            </ul>
          </div>
          <div className='navbar-menu-right'>
            <div className='theme-switch'>
              <button className={`switch-btn-light ${darkTheme ? '' : 'switch-theme'}`}>Light Theme</button>
              <button className={`switch-btn-dark ${darkTheme ? 'switch-theme' : ''}`} onClick={data2}>
                Dark Theme
              </button>
            </div>
            <button
              className='btn sign-up'
              data-toggle='modal'
              data-target='#our-partner'
              onClick={() => {
                setJointTeamModal(true);
                document.body.classList.add('modal-open');
              }}
            >
              Partner with us <span className='fa-icon db-arrow-right'></span>
            </button>
            <div className='hamburger' onClick={() => setToggleNavbar(true)}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </nav>
      {joinTeamModal && <JoinOurTeamModal joinTeamModal={joinTeamModal} setJointTeamModal={setJointTeamModal} />}
      {joinTeamModal && <div class='modal-backdrop fade show'></div>}
    </header>
  );
}

export default Header;
