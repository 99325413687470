import React from 'react';
import Layout from 'views/components/Layout/Layout';

function PrivacyPolicy() {
  return (
    <Layout>
      <main className='main'>
        <section className='job-opening position-relative privacy-wrap'>
          <div className='container custom-container'>
            <div className='row'>
              <div className='col-md-10 col-lg-8 mx-auto text-center'>
                <div className='job-opening__content'>
                  <h2 className='heading-1 heading-blueDark mb-4'>
                    Privacy <span>Policy</span>
                  </h2>
                </div>
              </div>
              <div className='col-md-12 mt-5'>
                <div className='job-opening_inner'>
                  <p className='privacy-sub-section'>
                    <span className='privacy-sub-heading'>EFFECTIVE: </span>
                    <span>January 1st 2021</span>
                  </p>
                  <p className='privacy-sub-section'>
                    Dabadu Inc. ( “<span className='privacy-sub-heading'>we</span>”, “
                    <span className='privacy-sub-heading'>us</span>” or “
                    <span className='privacy-sub-heading'>our</span>” ) respects your privacy. This Privacy Policy
                    describes how we collect, use, share and safeguard your Personal Information.
                  </p>
                  <div>
                    <h4 className='privacy-heading'>Scope</h4>
                    <p className='privacy-sub-section'>
                      <span className='privacy-sub-heading'>“Personal Information” </span>
                      as used in this Privacy Policy means information about an identifiable individual. This Privacy
                      Policy does not apply to Personal Information that we collect in our capacity as an employer; or
                      business contact information (including the name, title, business address and telephone number of
                      an employee of an organization) that we collect in the course of our business dealings; or certain
                      publicly available information.
                    </p>
                    <ul className='privacy-list'>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Collection: </span>
                          We may collect your name and contact information, cookies, and web tracking data from your
                          visit to our website or use of our software (including the general geographic location
                          associated with your IP address known as IP geolocation), payment information, business data,
                          your business performance, feedback, reviews and testimonials and your social media content.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Use: </span>
                          We may use your Personal Information to deliver, maintain and improve our website, our
                          software and services and your account, to analyze your business operations and evaluate its
                          performance and to communicate with you.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Sharing: </span>
                          We may share your Personal Information with our employees and affiliates and third-party
                          service providers who act on our behalf to process your Personal Information, send
                          communications or conduct marketing.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Consent: </span>
                          We may ask for your consent directly or imply your consent through your conduct.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Choices: </span>
                          You have choices regarding receiving electronic marketing messages from us and blocking
                          cookies.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Cookies & Tracking Technologies: </span>
                          We use cookies and other tracking technologies to track your activity on our website and our
                          software and to provide you with a more personal experience. You may configure your browser to
                          block cookies.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Safeguards: </span>
                          We protect Personal Information using reasonable technological, administrative, and physical
                          security measures that are appropriate to the sensitivity of the Personal Information.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>International Transfers: </span>
                          We transfer your Personal Information outside of Canada including to the U.S.A. for storing or
                          processing by our third-party service providers. Your Personal Information may be accessible
                          to law enforcement agencies, government agencies, courts, and national security authorities of
                          the foreign jurisdiction.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Accuracy: </span>
                          We will make reasonable efforts to ensure your Personal Information is accurate, complete and
                          up to date as necessary for the purposes described in this Privacy Policy.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Retention & Disposal: </span>
                          We retain your Personal Information only for as long as necessary to fulfill our purposes
                          described in this Privacy Policy or as required or permitted by applicable law.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Aggregated & Anonymized Data: </span>
                          We may aggregate and anonymize Personal Information and use, disclose or retain it without
                          restriction.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Access & Update: </span>
                          You may write to us and request access to your Personal Information. We will update your
                          Personal Information as appropriate.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Complaints: </span>
                          We will investigate all complaints made with respect to our compliance with privacy laws.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>California’s Shine the Light Law: </span>
                          California residents with an established business relationship with us can request information
                          once a year about sharing their Personal Information with third parties for the third parties
                          direct marketing purposes.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>California Privacy Rights for Minor Users: </span>
                          California residents under the age of 18 who are registered users of online sites, services or
                          applications may request and obtain removal of content or information they have publicly
                          posted.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Hyperlinked Websites: </span>
                          Our website may contain hyperlinks to third-party websites, which are not subject to this
                          Privacy Policy.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Changes to this Privacy Policy: </span>
                          We may update this Privacy Policy from time to time and will post the revised Privacy Policy
                          on our website.
                        </p>
                      </li>
                      <li>
                        <p className='privacy-sub-section'>
                          <span className='privacy-sub-heading'>Contact Us: </span>
                          You may contact our Privacy Officer using the contact information at the end of this Privacy
                          Policy.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Collecting Your Personal Information</h4>
                    <p className='privacy-sub-section'>
                      We may collect Personal Information about you, your business and your online activities when you
                      visit our website, use our software and services, interact with us in person, by telephone or by
                      email, SMS, or other electronic means, or interact with social media.
                    </p>
                    <p className='privacy-sub-section'>
                      We limit the amount and type of Personal Information we collect to that which is necessary for our
                      purposes. We do not target or knowingly collect Personal Information from persons under the age of
                      majority.
                    </p>
                    <p className='privacy-sub-section'>
                      The types of Personal Information we collect about you include but are not limited to the
                      following:
                    </p>
                    <ul className='privacy-list-2'>
                      <li>
                        <p>
                          <span className='privacy-sub-heading'>Name & Contact Information: </span>
                          we may collect your first and last name and your contact information such as your address,
                          phone number(s) and email address(es) for the purposes of identifying you, communicating with
                          you, responding to your requests and inquiries and registering your account with us.
                        </p>
                      </li>
                      <li>
                        <p>
                          <span className='privacy-sub-heading'>Cookies & Web Tracking Data: </span>
                          we may collect automatically certain data when you visit our website or use our software
                          including but not limited to the following:
                        </p>
                        <ul className='privacy-inner-list'>
                          <li>
                            <p>
                              Network and connection information such as your Internet Protocol (IP) address, the
                              general geographic location associated with your IP address (known as IP geolocation) and
                              information about your Internet service provider.
                            </p>
                          </li>
                          <li>
                            <p>
                              Computer and mobile device information such as device, application, browser type and
                              version, browser plug-in type and version, operating system or time zone setting.
                            </p>
                          </li>
                          <li>
                            <p>Authentication ad security credential information.</p>
                          </li>
                          <li>
                            <p>
                              Content interaction information such as content downloads, streams and playback details
                              including duration and number of simultaneous streams and downloads.
                            </p>
                          </li>
                          <li>
                            <p>
                              The full Uniform Resource Locators (URL) clickstream to, through and from our website and
                              our software, the date and time of your visit, content you viewed and searched for, page
                              response times, download errors, and page interaction information such as scrolling,
                              clicks and mouse-overs.
                            </p>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          <span className='privacy-sub-heading'>Payment Information: </span>
                          we may collect certain payment information including payment method, bank account information
                          and credit card information for the purposes of processing your payment for the software and
                          services that you procure from us.
                        </p>
                      </li>
                      <li>
                        <p>
                          <span className='privacy-sub-heading'>Business Data: </span>
                          we may collect certain business data when you use our software or services including but not
                          limited to your current and historical product sales and service transactions, inventory,
                          manufacturer and third-party data, and data contained in your Dealer Management System (DMS),
                          data regarding your personnel and their performance, your calendar of appointments for product
                          sales and service and your overall business performance. Some business data may constitute
                          Personal Information. We collect business data for the purposes of delivering and maintaining
                          our software and services including our website.
                        </p>
                      </li>
                      <li>
                        <p>
                          <span className='privacy-sub-heading'>
                            Client Performance, Feedback, Reviews & Testimonials:{' '}
                          </span>
                          we may use your name, the fact that you have procured our software and services, the details
                          of any improvement in the performance of your business as a result thereof, and your verbal or
                          written feedback or reviews of our software and services for our marketing purposes. We may
                          collect from you also written testimonials regarding your use of our software and services for
                          our marketing purposes including but not limited to display on our website.
                        </p>
                      </li>
                      <li>
                        <p>
                          <span className='privacy-sub-heading'>Social Media Content: </span>
                          if you connect or interact with us using your third-party social media account (Facebook,
                          Twitter, Instagram etc.), we may collect certain information about you from your social media
                          content including but not limited to your username, profile picture, preferences, likes,
                          dislikes, posts, messages and friend lists. The information we collect depends on your privacy
                          settings for your applicable social media account.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Using Your Personal Information</h4>
                    <p className='privacy-sub-section'>We may use your Personal Information as follows:</p>
                    <ul className='privacy-list-2'>
                      <li>
                        <p>for all the purposes described above;</p>
                      </li>
                      <li>
                        <p>to deliver and maintain your account with us;</p>
                      </li>
                      <li>
                        <p>
                          to deliver, maintain and improve our software and services (including but not limited testing,
                          data analysis and troubleshooting);
                        </p>
                      </li>
                      <li>
                        <p>to analyze your business operations and evaluate its performance;</p>
                      </li>
                      <li>
                        <p>
                          to deliver and maintain our website, monitor its usage and improve its functionality and user
                          experience;
                        </p>
                      </li>
                      <li>
                        <p>
                          to protect against theft, fraud, cyberattacks or similar risks and to improve our security;
                        </p>
                      </li>
                      <li>
                        <p>
                          to communicate with you regarding our software and services that you procure from us including
                          but not limited to their functionalities and providing you with security and other updates
                          related thereto;
                        </p>
                      </li>
                      <li>
                        <p>
                          to provide you with news, special offers and general information about our software and
                          services and events, unless you have opted not to receive such information;
                        </p>
                      </li>
                      <li>
                        <p>
                          to comply with and enforce the terms of our agreements and resolve any disputes between us;
                          and
                        </p>
                      </li>
                      <li>
                        <p>for any purpose permitted or required by law.</p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Sharing Your Personal Information</h4>
                    <p className='privacy-sub-section'>
                      We may share your Personal Information with the following persons or in the following situations
                    </p>
                    <ul className='privacy-list-2'>
                      <li>
                        <p>our employees, parent company, affiliates, subsidiaries, licensors and partners;</p>
                      </li>
                      <li>
                        <p>
                          our third-party service providers who act on our behalf to store or process Personal
                          Information, send communications or conduct marketing;
                        </p>
                      </li>
                      <li>
                        <p>
                          when we believe in good faith that disclosure is necessary to protect our rights, protect your
                          safety or the safety of others, investigate fraud or respond to a law enforcement request; and
                        </p>
                      </li>
                      <li>
                        <p>
                          as required or permitted by applicable law such as pursuant to a subpoena, government inquiry,
                          litigation, dispute resolution or similar legal process.
                        </p>
                      </li>
                    </ul>
                    <p className='privacy-sub-section'>
                      Except as stated in this Privacy Policy, we will not share your Personal Information with any
                      person without your prior consent.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Consent</h4>
                    <p className='privacy-sub-section'>
                      The manner in which we obtain your consent to collecting, using or disclosing your Personal
                      Information varies depending upon its sensitivity and applicable privacy law. We may ask for your
                      consent directly or imply your consent through your conduct when it is appropriate for us to do
                      so. In certain situations, when legally permissible for us to do so, we may collect, use or
                      disclose your Personal Information without your consent.
                    </p>
                    <p className='privacy-sub-section'>
                      We will honour any specific consents you provide to us and you may withdraw your consent at any
                      time subject to legal and contractual restrictions and by providing us reasonable notice. If
                      appropriate, we will notify you of any implications of withdrawing your consent.
                    </p>
                    <p className='privacy-sub-section'>
                      We will not, as a condition to the supply of our software or services, require you to consent to
                      the collection, use or disclosure of your Personal Information beyond that which we require for
                      our purposes.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Choices</h4>
                    <p className='privacy-sub-section'>
                      You may choose whether to receive electronic marketing messages from us by opting in when given
                      the choice or following the unsubscribe instructions in email or SMS marketing messages that you
                      receive from us.
                    </p>
                    <p className='privacy-sub-section'>
                      We cannot block cookies. You must configure your browser settings to block all cookies from our
                      domain. Please see the <a href='#cookies-tracking'>Cookies & Tracking Technologies</a> section
                      below for details.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading' id='cookies-tracking'>
                      Cookies & Tracking Technologies
                    </h4>
                    <p className='privacy-sub-section'>
                      We use cookies and other similar tracking technologies to automatically track your activity on our
                      website and our software, provide you with a more personal experience such as remembering your
                      account login details and language preference, and for the other uses outlined above
                    </p>
                    <p className='privacy-sub-section'>
                      Cookies are small files that are placed on your computer or mobile device by a website and that
                      contain details about your browsing history on that website. Some cookies are deleted at the end
                      of your browsing session while others persist between sessions. Tracking technologies used are
                      beacons, tags and scripts to collect and track information and to improve and analyze our website.
                      Some cookies and tracking technologies are essential to our software such as those used to
                      authenticate you and prevent misuse of your account.
                    </p>
                    <p className='privacy-sub-section'>
                      You may configure your web browser to refuse all cookies or to indicate when a cookie is being
                      sent. However, if you do not accept cookies, you may not be able to use some parts of our website
                      or our software and your experience on our website or our software may be hindered.
                    </p>
                    <p className='privacy-sub-section'>
                      We use third party analytics services including Google Analytics to create reports and statistics
                      on visits to our website and use of our software. For information on how Google Analytics collects
                      and processes your data, please see “How Google uses data when you use our partners’ sites and
                      apps” located at{' '}
                      <a href='https://www.google.com/policies/privacy/partners/'>
                        https://www.google.com/policies/privacy/partners/
                      </a>
                      .
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Safeguards</h4>
                    <p className='privacy-sub-section'>
                      We protect Personal Information against loss, theft and unauthorized access or disclosure by using
                      reasonable technological, administrative, and physical security measures that are appropriate to
                      the sensitivity of the Personal Information within our possession or control. These measures
                      include using encryption protocols and software, training our staff on the importance of
                      maintaining the confidentiality and security of Personal Information and requiring our third-party
                      service providers acting on our behalf to enter into written agreements with us that require them
                      to secure the Personal Information we share with them.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>International Transfers</h4>
                    <p className='privacy-sub-section'>
                      We transfer your Personal Information outside of Canada including but not limited to the United
                      States of America for storing or processing by our third party service providers for some or all
                      of the purposes described in this Privacy Policy. While we use contractual and other means to
                      ensure your Personal Information is protected while in the foreign jurisdiction, it may be still
                      accessible to law enforcement agencies, government agencies, courts and national security
                      authorities of the foreign jurisdiction.
                    </p>
                    <p className='privacy-sub-section'>
                      If you have any questions about our policies and practices regarding our service providers outside
                      of Canada, please contact our Privacy Officer using the contact information at the end of this
                      Privacy Policy.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Accuracy</h4>
                    <p className='privacy-sub-section'>
                      We will make reasonable efforts to ensure your Personal Information is accurate, complete and up
                      to date as necessary for the purposes described in this Privacy Policy. This includes your
                      Personal Information that is shared with third parties or that is used to make a decision about
                      you. Our reasonable efforts include communicating with you to correct information when we believe
                      it has been incorrectly provided.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Retention & Disposal</h4>
                    <p className='privacy-sub-section'>
                      We retain your Personal Information only for as long as necessary to fulfill our purposes
                      described in this Privacy Policy or as required or permitted by applicable law. Once no longer
                      required, we will anonymize or destroy your Personal Information.
                    </p>
                    <p className='privacy-sub-section'>
                      When determining retention periods, we consider certain criteria including the following:
                    </p>
                    <ul className='privacy-list-2'>
                      <li>
                        <p>
                          whether the purposes for which your Personal Information was collected have been fulfilled;
                        </p>
                      </li>
                      <li>
                        <p>
                          whether anonymizing or destroying your Personal Information will impact the software or
                          services procured by you;
                        </p>
                      </li>
                      <li>
                        <p>
                          whether your Personal Information has been used to make a decision about you in which case we
                          will continue to retain that Personal Information for at least one year after using it to make
                          the decision to reasonably allow you time to access that Personal Information and to exhaust
                          any access request or challenge you may wish to bring under applicable privacy law; and
                        </p>
                      </li>
                      <li>
                        <p>retention periods required by law.</p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Aggregated & Anonymized Data</h4>
                    <p className='privacy-sub-section'>
                      We may aggregate and anonymize Personal Information within our possession or control and use,
                      disclose or retain it without restriction including but not limited to enhancing our software and
                      services, developing new products and services or for our marketing purposes.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Accessing & Updating Your Personal Information</h4>
                    <p className='privacy-sub-section'>
                      You have a right to access your Personal Information within our possession or control. Upon
                      receiving a written access request from you and upon verifying your identity, we will inform you
                      of the existence, use and sharing of your Personal Information and provide you with access to it.
                      We will respond to your written access request within 30 days or inform you if we require
                      additional time to respond. We may charge you a minimal handling fee. In certain situations, it
                      may be legally permissible for us to refuse your access request and, upon your request, we will
                      provide you with an explanation for the refusal.
                    </p>
                    <p className='privacy-sub-section'>
                      You may question the accuracy and completeness of your Personal Information and request that
                      amendments be made to it as appropriate. If you demonstrate in a reasonable manner the inaccuracy
                      or incompleteness of your Personal Information, we will amend your Personal Information as
                      required. If a request is not resolved to your satisfaction, we will record the substance of the
                      unresolved request. Where appropriate, your amended Personal Information or the existence of the
                      unresolved request will be transmitted to the third parties with whom we’ve shared your Personal
                      Information in question.
                    </p>
                    <p className='privacy-sub-section'>
                      To make a written access request for your Personal Information, please contact our Privacy Officer
                      using the contact information at the end of this Privacy Policy.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Complaints</h4>
                    <p className='privacy-sub-section'>
                      Upon your request, we will provide you with information regarding our procedure for addressing any
                      complaints made with respect to our compliance with privacy laws. We will investigate all written
                      complaints and, if we find a complaint to be justified, we will take the appropriate measures
                      including, if necessary, amending our policies and practices.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>
                      Your California Privacy Rights (California's Shine the Light law)
                    </h4>
                    <p className='privacy-sub-section'>
                      Under California Civil Code Section 1798 (California's Shine the Light law), California residents
                      with an established business relationship with us can request information once a year about
                      sharing their Personal Information with third parties for the third parties' direct marketing
                      purposes
                    </p>
                    <p className='privacy-sub-section'>
                      If you'd like to request more information under the California Shine the Light law, and if you are
                      a California resident, please contact our Privacy Officer using the contact information at the end
                      of this Privacy Policy
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>
                      California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)
                    </h4>
                    <p className='privacy-sub-section'>
                      California Business and Professions Code section 22581 allow California residents under the age of
                      18 who are registered users of online sites, services or applications to request and obtain
                      removal of content or information they have publicly posted.
                    </p>
                    <p className='privacy-sub-section'>
                      To request removal of such data, and if you are a California resident, please contact our Privacy
                      Officer using the contact information at the end of this Privacy Policy and include the email
                      address associated with your account.
                    </p>
                    <p className='privacy-sub-section'>
                      Please be aware that your request does not guarantee complete or comprehensive removal of content
                      or information posted online and that the law may not permit or require removal in certain
                      circumstances.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Hyperlinked Websites</h4>
                    <p className='privacy-sub-section'>
                      Our website and software may contain hyperlinks to websites that are not operated by us and
                      therefore not subject to this Privacy Policy. If you click on a third party link, you will be
                      directed to that third party’s website. We advise you to review carefully the privacy policy of
                      every third party website you visit. We have no control over the privacy policies and practices of
                      these third parties.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Changes to this Privacy Policy</h4>
                    <p className='privacy-sub-section'>
                      We periodically review our privacy policies and practices to ensure they are relevant, up to date
                      and comply with applicable privacy law. Accordingly, we may update this Privacy Policy from time
                      to time and post the revised version on our website. You are advised to review this Privacy Policy
                      periodically for any changes. Changes to this Privacy Policy are effective and binding on you when
                      they are posted on our website.
                    </p>
                  </div>
                  <div>
                    <h4 className='privacy-heading'>Contact Us</h4>
                    <p className='privacy-sub-section'>
                      If you have any questions or concerns about this Privacy Policy, please contact our Privacy
                      Officer at:
                    </p>
                    <p className='mb-1 privacy-contact-item'>Privacy Officer</p>
                    <p className='mb-1 privacy-contact-item'>Dabadu Inc.</p>
                    <p className='mb-1 privacy-contact-item'>Suite 130 – 4275 King Street E</p>
                    <p className='mb-1 privacy-contact-item'>Kitchener Ontario N2P2E9</p>
                    <p className='mb-1 privacy-contact-item'>Telephone: 1 (844) 605-0101</p>
                    <a href='mailto:privacy@dabadu.ai'>privacy@dabadu.ai</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span className='team-shape-1'></span>
          <span className='team-shape-2'></span>
          <span className='career-shape-1'></span>
          <span className='career-shape-2'></span>
        </section>
      </main>
    </Layout>
  );
}

export default PrivacyPolicy;
