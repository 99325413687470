import React, { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "views/components/Layout/Layout";
import { commonImages } from "views/constants/common";
import JoinOurTeamModal from "../JoinOurTeamModal";

function InventoryManagement() {
  const {
    inventoryFeeds,
    shareOptions,
    dashboardReports,
    multiVehicleSelection,
    inventory,
  } = commonImages;
  const [joinTeamModal, setJointTeamModal] = useState(false);

  return (
    <Layout products={true}>
      <main className="main">
        <section className="inventory-main position-relative text-center">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-10 mx-auto mb-5">
                <div className="inventory-main__content">
                  <h1 className="heading-1 heading-blueDark mb-3">
                    Dabadu <span className="d-block">Inventory Management</span>
                  </h1>
                  <p className="textStyle-2">
                    Customers can often be overwhelmed when looking at
                    similar vehicle options across different model years and
                    trims. You can share a summary of the top
                    vehicle picks with them to help them make the best
                    decision.
                  </p>
                  <div className="d-flex align-items-center justify-content-center mt-4 mt-md-5 pt-md-5">
                    <Link
                      to="/current-opening"
                      className="btn btn-primary mr-4"
                    >
                      Join our team
                    </Link>
                    <button
                      className="btn btn-secondary"
                      data-toggle="modal"
                      data-target="#our-partner"
                      onClick={() => {
                        setJointTeamModal(true);
                        document.body.classList.add("modal-open");
                      }}
                    >
                      Partner with us <span className="fa-icon db-arrow-right"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-11 mx-auto mt-md-5 mt-md-5">
                <div className="inventory-main__img">
                  <img src={inventory} className="img-fluid" alt="inventory" />
                </div>
              </div>
            </div>
          </div>
          <span className="inv-shape-1"></span>
          <span className="inv-shape-2"></span>
          <span className="inv-shape-3"></span>
        </section>

        <section className="key-features position-relative">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <h3 className="heading-3 heading-blueDark mb-4">
                  Key <span>Features</span>
                </h3>
                <p className="textStyle-2">
                  You need to know what units are coming and going through your business.
                  You need to know what models are hot… and what models are not. Dabadu Inventory
                  Management has made it simple to find any vehicle at any of
                  your dealerships.
                </p>
              </div>
            </div>
            <div className="key-features__slider mt-5 pt-5">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="key-features__slider-block feature-color-1">
                    <img src={inventoryFeeds} alt="inventoryFeed" />
                    <h4 className="heading-4 mb-4">
                      Inventory <br />
                      feeds
                    </h4>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="key-features__slider-block feature-color-2">
                    <img src={shareOptions} alt="shareOptions" />
                    <h4 className="heading-4 mb-4">
                      Share <br />
                      Options
                    </h4>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="key-features__slider-block feature-color-3">
                    <img src={dashboardReports} alt="dashboardReports" />
                    <h4 className="heading-4 mb-4">
                      Dashboards / <br />
                      Reports
                    </h4>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="key-features__slider-block feature-color-4">
                    <img
                      src={multiVehicleSelection}
                      alt="multiVehicleSelection"
                    />
                    <h4 className="heading-4 mb-4">Multi-vehicle selection</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span className="xrm-shape-7"></span>
        </section>
      </main>
      {joinTeamModal && (
        <JoinOurTeamModal
          joinTeamModal={joinTeamModal}
          setJointTeamModal={setJointTeamModal}
        />
      )}
      {joinTeamModal && <div class="modal-backdrop fade show"></div>}
    </Layout>
  );
}

export default InventoryManagement;
