import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import axios from 'axios';

function CurrentOpening() {
  const [response, setResponse] = useState('');

  useEffect(() => {
    axios({
      // Endpoint to send files
      url: 'https://dabadu.bamboohr.com/jobs/embed2.php',
      method: 'GET',
    }).then((res) => {
      setResponse(res?.data);
    });
  }, []);

  return (
    <Layout careers={true}>
      <main className='main'>
        <section className='job-opening position-relative'>
          <div className='container custom-container'>
            <div className='row'>
              <div className='col-md-10 col-lg-8 mx-auto text-center'>
                <div className='job-opening__content'>
                  <h2 className='heading-1 heading-blueDark mb-4'>
                    Current <span>Openings</span>
                  </h2>
                  <p className='textStyle-2'>
                    Thanks for checking out our job openings. See something that interests you? Apply here.
                  </p>
                </div>
              </div>

              <div className='col-md-12 mt-5'>
                <div className='job-opening_inner'>
                  {/* For Future Use */}
                  {/* <div
                    className="
                            d-flex
                            align-items-center
                            justify-content-between
                            job-search
                            mb-5
                          "
                  >
                    <div className="col-md-4">
                      <div className="form-group">
                        <input
                          type="search"
                          id="myInput"
                          onKeyUp={searchFunc}
                          placeholder="Search for jobs"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-4 p-0">
                      <div className="row w-100 m-0 align-items-center">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="heading-7">Department</label>
                            <select
                              className="form-control"
                              name="department"
                              id="department"
                              size="1"
                            >
                              <option value="0">All Departments</option>
                              <option value="1">Product Management</option>
                              <option value="2">Software Engineering</option>
                              <option value="3">Support</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="heading-7">Location</label>
                            <select
                              className="form-control"
                              name="location"
                              id="location"
                              size=""
                            >
                              <option value="0">All Locations</option>
                              <option value="1">Kitchener</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: response,
                    }}
                  ></div>
                  {/* For Future Use */}
                  {/* <div id="myTable0">
                    <div
                      className="table-responsive-sm table-main"
                      id="myTable1"
                    >
                      <h3>Product Management</h3>
                      <table
                        className="table mb-0"
                        id="table1"
                        data-name="mytable"
                      >
                        <tr className="location1">
                          <td>
                            <a
                              href="https://dabadu.bamboohr.com/jobs/view.php?id=22&source=other"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Business Analyst
                            </a>
                          </td>
                          <td>
                            <p className="mb-0">Kitchener</p>
                            <span>Ontario</span>
                          </td>
                          <td>
                            <p className="mb-0">Product Management</p>
                            <span>Co-op</span>
                          </td>
                        </tr>
                        <tr className="location1">
                          <td>
                            <a
                              href="https://dabadu.bamboohr.com/jobs/view.php?id=24&source=other"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Product Owner
                            </a>
                          </td>
                          <td>
                            <p className="mb-0">Kitchener</p>
                            <span>Ontario</span>
                          </td>
                          <td>
                            <p className="mb-0">Product Management</p>
                            <span>Full-Time</span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div
                      className="table-responsive-sm table-main"
                      id="myTable2"
                      data-name="mytable"
                    >
                      <h3>Software Engineering</h3>
                      <table
                        className="table mb-0"
                        id="table2"
                        data-name="mytable"
                      >
                        <tr className="location1">
                          <td>
                            <a
                              href="https://dabadu.bamboohr.com/jobs/view.php?id=26&source=other"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Senior QA Automation Engineer
                            </a>
                          </td>
                          <td>
                            <p className="mb-0">Kitchener</p>
                            <span>Ontario</span>
                          </td>
                          <td>
                            <p className="mb-0">Software Engineering</p>
                            <span>Full-Time</span>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div
                      className="table-responsive-sm table-main"
                      id="myTable3"
                      data-name="mytable"
                    >
                      <h3>Support</h3>
                      <table
                        className="table mb-0"
                        id="table3"
                        data-name="mytable"
                      >
                        <tr className="location1">
                          <td>
                            <a
                              href="https://dabadu.bamboohr.com/jobs/view.php?id=25&source=other"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Customer Experience Rep
                            </a>
                          </td>
                          <td>
                            <p className="mb-0">Kitchener</p>
                            <span>Ontario</span>
                          </td>
                          <td>
                            <p className="mb-0">Support</p>
                            <span>Full-Time</span>
                          </td>
                        </tr>
                        <tr className="location1">
                          <td>
                            <a
                              href="https://dabadu.bamboohr.com/jobs/view.php?id=23&source=other"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Customer Success Manager
                            </a>
                          </td>
                          <td>
                            <p className="mb-0">Kitchener</p>
                            <span>Ontario</span>
                          </td>
                          <td>
                            <p className="mb-0">Support</p>
                            <span>Full-Time</span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <span className='team-shape-1'></span>
          <span className='team-shape-2'></span>
          <span className='career-shape-1'></span>
          <span className='career-shape-2'></span>
          <span className='career-shape-3'></span>
        </section>
      </main>
    </Layout>
  );
}

export default CurrentOpening;
